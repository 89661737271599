import { Banner } from '../../../components/Banner/Banner'

const ItemListApproval = () => {
  return (
    <Banner
      subtext="Changes have been made to the item list from the Vendor side. Please proceed to the Pending tab, where you can select rows to approve or decline."
      text="Item list approval"
    />
  )
}

export default ItemListApproval
