import {
  CellTemplate,
  Compatible,
  Uncertain,
  Cell,
  UncertainCompatible
} from '@silevis/reactgrid'
import { Select } from '../../../../components/Select'
import { PLACEHOLDERS } from '../../../../constants'
import { TOption } from '../../../../components/Select/types'

export interface SelectCellInterface extends Cell {
  type: 'dropdown'
  values: TOption[]
  text: string | null
  disabled?: boolean
  focusCallback?: () => void
  unFocusCallback?: () => void
  isError?: boolean
}

export class CustomCellSelectTemplate
  implements CellTemplate<SelectCellInterface>
{
  getCompatibleCell(
    uncertainCell: Uncertain<SelectCellInterface>
  ): Compatible<SelectCellInterface> {
    return {
      ...uncertainCell,
      text: uncertainCell.text ?? '',
      value: uncertainCell.values?.length || 0,
      values: uncertainCell.values || [],
      disabled: uncertainCell.disabled
    }
  }
  update(
    cell: Compatible<SelectCellInterface>,
    cellToMerge: UncertainCompatible<SelectCellInterface>
  ): Compatible<SelectCellInterface> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text
    })
  }

  render(
    cell: Compatible<SelectCellInterface>,
    _isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<SelectCellInterface>,
      commit: boolean
    ) => void
  ) {
    return (
      <Select.DefaultNoForm
        className="full-width"
        options={cell.values}
        hasCustomError={cell.isError}
        propsSelect={{
          className: 'full-width',
          value: cell.text ?? undefined,
          // TODO: check this issue
          // value: cell.text.length ? cell.text : null,
          placeholder: PLACEHOLDERS.PLEASE_SELECT,
          onChange: (selectedOption) => {
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: selectedOption }),
              true
            )
          },
          disabled: cell.disabled,
          onFocus: () => cell.focusCallback?.(),
          onBlur: () => cell.unFocusCallback?.()
        }}
      />
    )
  }

  isFocusable() {
    return true
  }
}
