import { useContractDetailsPopup } from '../../ContractDetails/Providers/ContractDetailsPopupProvider'
import { Popup } from '../../../components/Popup'
import { Table } from '../../../components/Table'
import {
  TContractItemsListParams,
  TContractItemsListRecord,
  TContractItemsRequestData
} from '../types'
import { ReactComponent as ArrowRight16 } from 'assets/svg/ArrowRight16.svg'
import { ReactComponent as ArrowDown16 } from 'assets/svg/ArrowDown16.svg'
import { useEffect, useState } from 'react'
import { setLoading } from '../../../redux/store/common/slice'
import { getReplacedHistoryTableData } from '../api'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import { useDispatch } from 'react-redux'
import { useColumns } from './useColumns'
import { useContractDetailsContext } from '../../ContractDetails/Providers/ContractDetailsContextProvider'
import './style.scss'

type TProps = {
  compareId?: string
}

const CompareRecordPopup = ({ compareId }: TProps) => {
  const { compareRecordsPopup } = useContractDetailsPopup()
  const contractDetailsContext = useContractDetailsContext()
  const { details } = contractDetailsContext.state
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState<TContractItemsListRecord[]>([])
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const { columns } = useColumns(details.uuid, tableData, false, compareId)

  useEffect(() => {
    getTableData({})
  }, [compareId, details.uuid])

  const getTableData = ({ page }: TContractItemsRequestData) => {
    if (!compareId) return
    dispatch(setLoading(true))
    const params: TContractItemsListParams = {}
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    details.uuid &&
      getReplacedHistoryTableData(
        {
          params
        },
        details.uuid,
        compareId
      )
        .then((resp) => {
          if (!resp.data?.results) {
            return
          }
          const resultedDataChildren = resp.data.results.map((i) => {
            return {
              ...i.record,
              key: i.uuid
            }
          }) as any

          setTableData([
            {
              name: resultedDataChildren[0]?.supplier_item_number,
              uuid: '1',
              key: '1',
              children: resultedDataChildren
            }
          ])
          setTotalNumber(resp.data.count)
        })
        .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      page
    })
    setPageInfo(page)
  }
  const { actions, state } = compareRecordsPopup
  return (
    <Popup {...state} onCancel={actions.close} width={1376} footer={null}>
      <Table<TContractItemsListRecord>
        dataSource={tableData}
        className="contacts-duplicates-item-list-table recompare"
        columns={columns}
        scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
        onChange={handleTableChange}
        onChangePage={handleChangePageSize}
        pageSize={pageInfo.pageSize}
        pagination={{
          pageSize: pageInfo.pageSize,
          current: pageInfo.pageNumber,
          total: totalNumber
        }}
        expandable={{
          defaultExpandAllRows: true,
          expandIcon: ({ expanded, onExpand, record, expandable }) =>
            expandable ? (
              expanded ? (
                <div
                  className="expand-wrapper row gap-12 align-center"
                  onClick={(e: any) => onExpand(record, e)}
                >
                  <ArrowDown16 />
                </div>
              ) : (
                <div
                  className="expand-wrapper row gap-12 align-center"
                  onClick={(e: any) => onExpand(record, e)}
                >
                  <ArrowRight16 />
                </div>
              )
            ) : (
              <></>
            )
        }}
      />
    </Popup>
  )
}

export default CompareRecordPopup
