import { BTN_TXT } from '../../../constants'
import { Banner } from '../../../components/Banner/Banner'
import useRouter from '../../../hooks/useRouter'
import { routes } from '../../../router'
import { canSeeMyContracts } from '../../../helper/common'
import { useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'
import { IContractDetails } from '../types'

export const DuplicatesBanner = ({
  details
}: {
  details: IContractDetails
}) => {
  const { navigate } = useRouter()
  const user = useSelector(getUser)

  const numberOfDuplicates =
    details.pending_reconciliation?.required_items_count ?? 0

  if (user.uuid !== details.pending_reconciliation?.user) return null

  return (
    <Banner
      button={{
        onButtonClick: () =>
          navigate(
            `${routes.contracts}${
              canSeeMyContracts(user.role) ? routes.myContracts : ''
            }/${details.uuid}/item_list?withDuplicatesModal=true`
          ),
        buttonText: BTN_TXT.RECONCILE_DUPLICATES
      }}
      className="mb-16 space-between"
      text={`During the item list upload, we found ${numberOfDuplicates} duplicate${
        numberOfDuplicates > 1 ? 's' : ''
      } based on the Supplier Item Number. Please reconcile duplicate${
        numberOfDuplicates > 1 ? 's' : ''
      } to proceed.`}
    />
  )
}
