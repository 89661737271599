import { Input } from 'components/Input'
import { Select } from '../../../../../components/Select'
import { percentageRebateValidator } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/utils'
import { LABELS, PLACEHOLDERS } from 'constants/txt'
import {
  MONTH_OPTIONS,
  PROPS_INPUT_NUMBER
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { StraightRebateProps } from './types'

const StraightRebateCondition = ({ handleChangeForm }: StraightRebateProps) => (
  <div className="row gap-12">
    <Input.Number
      propsItem={{
        name: 'amount',
        label: LABELS.REBATE_AMOUNT,
        rules: [
          {
            validator: percentageRebateValidator
          }
        ]
      }}
      propsInputNumber={PROPS_INPUT_NUMBER}
    />
    <Select
      options={MONTH_OPTIONS}
      propsItem={{
        name: 'period_threshold',
        label: LABELS.PERIOD_THRESHOLD
      }}
      propsSelect={{
        placeholder: PLACEHOLDERS.PLEASE_SELECT,
        onChange: handleChangeForm
      }}
    />
  </div>
)

export default StraightRebateCondition
