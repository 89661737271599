import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { Space } from 'antd'
import Radio, { RadioChangeEvent } from 'antd/lib/radio'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import Popup from 'components/Popup/Popup'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ACTIONS, Can, ROLES, SUBJECTS } from '../Permission'
import useRouter from '../../hooks/useRouter'
import { getUser } from '../../redux/store/user/getters'
import { CONTRACT_TYPE_OPTIONS } from './constants'
import { routes } from '../../router'
import { BTN_TXT } from 'constants/txt'

const CreateContractType = () => {
  const [value, setValue] = useState('community')
  const user = useSelector(getUser)
  const { push, query } = useRouter()
  const [isOpen, setIsOpen] = useState(false)

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  const onSend = () => {
    push(
      `${routes.contracts}/${
        query?.type ? query.type : ''
      }/create?typeCreation=${value}`
    )
  }
  const onCancel = () => {
    setIsOpen(false)
    setValue('community')
  }
  const createContact = () => {
    if (user.role === ROLES.COMMUNITY_PRESIDENT) {
      onSend()
    } else if (
      user.role === ROLES.COMMONS_VICE_PRESIDENT ||
      user.role === ROLES.COMMONS_CONTRACT_STEWARD
    ) {
      push(
        `${routes.contracts}/${
          query?.type ? query.type : ''
        }/create?typeCreation=commons`
      )
    } else {
      setIsOpen(true)
    }
  }

  return (
    <>
      <Can I={ACTIONS.CREATE} a={SUBJECTS.CONTRACTS}>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          icon={<Plus16 />}
          onClick={createContact}
          upperCase
        >
          {BTN_TXT.CREATE_CONTACT}
        </Button>
      </Can>
      <Popup
        visible={isOpen}
        onCancel={onCancel}
        title={'Contract type '}
        width={453}
        className="run-new-step-modal"
        footer={
          <>
            <Button onClick={onCancel} type={BUTTON_TYPES.DEFAULT} upperCase>
              {BTN_TXT.CANCEL}
            </Button>
            <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={onSend}>
              {BTN_TXT.CONTINUE}
            </Button>
          </>
        }
      >
        <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          To create a contract, please select contract type:
          <Radio.Group
            onChange={onChange}
            value={value}
            className={cn('radio-group-default', 'mt-16')}
          >
            <Space direction="vertical">
              <>
                {CONTRACT_TYPE_OPTIONS.map((i) => (
                  <Fragment key={i.value}>
                    <Radio value={i.value}>
                      <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
                        {i.label}
                      </Typography.Body1>
                    </Radio>
                    {i.value === CONTRACT_TYPE_OPTIONS[1].value ? (
                      <Typography.Caption className="run-new-step-option__message">
                        can be used by the selected Health System only
                      </Typography.Caption>
                    ) : null}
                    {i.value === CONTRACT_TYPE_OPTIONS[0].value ? (
                      <Typography.Caption className="run-new-step-option__message">
                        can be shared with other Health Systems inside Community
                      </Typography.Caption>
                    ) : null}
                  </Fragment>
                ))}
              </>
            </Space>
          </Radio.Group>
        </Typography.Body1>
      </Popup>
    </>
  )
}

export default CreateContractType
