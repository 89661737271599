import { useDispatch, useSelector } from 'react-redux'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { onMessage } from 'firebase/messaging'
import NavLink from '../../components/NavLink/NavLink'
import InfiniteList from '../../components/InfiniteList/InfiniteList'
import { NotificationItemList } from './NotificationItemList/NotificationItemList'
import { Drawer } from '../../components/Drawer'
import { Switch } from '../../components/Switch'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../components/Typography'
import { Chips } from '../../components/Chips/Chips'
import useInfiniteData from '../../hooks/useInfiniteData'
import {
  getIsModalOpen,
  getUnreadNotificationsAmount
} from '../../redux/store/notifications/getters'
import {
  setIsModalOpen,
  setToken,
  setUnreadNotificationsAmount
} from '../../redux/store/notifications/slice'
import { setLoading } from '../../redux/store/common/slice'
import { formatFirebaseResponse, getIsFirstOfTheDay } from './utils'
import { getUser } from '../../redux/store/user/getters'
import {
  getBadgeCount,
  getDevice,
  getNotificationsList,
  initDevice,
  markAllNotificationsAsRead,
  markNotificationAsRead
} from './api'
import { getTokenInit, messaging } from '../../firebase'
import { downloadFile } from '../../helper/common'
import { ReactComponent as NotificationsIcon } from 'assets/svg/Notifications.svg'
import { ReactComponent as EmptyBox } from 'assets/svg/EmptyBox.svg'
import {
  BTN_TXT,
  FIREBASE_MESSAGES_TYPES,
  FIREBASE_STATUSES,
  VALIDATION_MESSAGES
} from '../../constants'
import { useBottomNotificationsContext } from '../BottomNotificationProvider/BottomNotificationsContextProvider'
import {
  TNotificationType,
  TNotificationTypeName
} from '../BottomNotificationProvider/types'
import { FirebaseMessageData, MarkAsReadFunc, NotificationItem } from './types'
import './styles.scss'
import { RECONCILIATION_STATUSES } from '../ContractItems/types'
import { notification } from '../../components/Notification'

const limit = 10

export const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isUnreadOnly, setIsUnreadOnly] = useState(false)
  const [total, setTotal] = useState(0)
  const [notificationsList, setNotificationsList] = useState<
    NotificationItem[]
  >([])
  const cancelToken = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const isOpen = useSelector(getIsModalOpen)
  const unreadNotifications = useSelector(getUnreadNotificationsAmount)
  const user = useSelector(getUser)

  const { actions } = useBottomNotificationsContext()

  useEffect(() => {
    document.addEventListener('click', onClickOutside)
    return () => document.removeEventListener('click', onClickOutside)
  }, [])

  useEffect(() => {
    if (user.uuid) {
      initNotifications()
      onMessage(messaging, (message) => {
        if (
          message?.data?.type ===
            FIREBASE_MESSAGES_TYPES.CONTRACT_BULK_CREATION_MESSAGE_TYPE ||
          message?.data?.type ===
            FIREBASE_MESSAGES_TYPES.REJECTED_INVOICES_DOWNLOAD_MESSAGE_TYPE
        ) {
          const { status, task_id } = message.data
          actions.onChangeNotification(
            {
              id: task_id,
              type:
                status === FIREBASE_STATUSES.COMPLETED
                  ? TNotificationType.SUCCESS
                  : TNotificationType.ERROR
            },
            message?.data?.type ===
              FIREBASE_MESSAGES_TYPES.CONTRACT_BULK_CREATION_MESSAGE_TYPE
              ? TNotificationTypeName.LOADING_CONTRACTS
              : TNotificationTypeName.EXPORT_REJECTED_INVOICES
          )
          if (
            status === FIREBASE_STATUSES.COMPLETED &&
            message?.data?.type ===
              FIREBASE_MESSAGES_TYPES.REJECTED_INVOICES_DOWNLOAD_MESSAGE_TYPE &&
            message?.data?.file_url
          ) {
            downloadFile(message.data.file_url)
          }
        } else if (
          message?.data?.type ===
          FIREBASE_MESSAGES_TYPES.CONTRACT_ITEMS_FILE_DOWNLOAD_MESSAGE_TYPE
        ) {
          const { notification_status, event_id, file_url } = message.data
          actions.onChangeNotification(
            {
              id: event_id,
              type:
                notification_status === FIREBASE_STATUSES.COMPLETED
                  ? TNotificationType.SUCCESS
                  : TNotificationType.ERROR
            },
            TNotificationTypeName.DOWNLOAD_CURRENT_ITEM_LIST
          )
          if (notification_status === FIREBASE_STATUSES.COMPLETED && file_url) {
            downloadFile(file_url)
          }
        } else if (
          message?.data?.type ===
          FIREBASE_MESSAGES_TYPES.UPLOAD_TEM_LIST_FINISHED_MESSAGE_TYPE
        ) {
          const { status, reconciliation_id, reconciliation_status } =
            message.data
          actions.onChangeNotification(
            {
              id: reconciliation_id,
              type:
                status === FIREBASE_STATUSES.COMPLETED &&
                reconciliation_status === RECONCILIATION_STATUSES.COMPLETED
                  ? TNotificationType.SUCCESS
                  : TNotificationType.ERROR,
              messageProps:
                reconciliation_status ===
                RECONCILIATION_STATUSES.WAITING_FOR_REVIEW
                  ? {
                      specificErrorMessage: {
                        message: 'Duplicates found during upload',
                        description: 'Reconcile duplicates to proceed.'
                      }
                    }
                  : undefined
            },
            TNotificationTypeName.UPLOAD_ITEM_LIST
          )

          if (
            status !== FIREBASE_STATUSES.COMPLETED &&
            reconciliation_status !== RECONCILIATION_STATUSES.WAITING_FOR_REVIEW
          ) {
            notification.error({ message: VALIDATION_MESSAGES.SM0061 })
          }
        } else {
          const notification = formatFirebaseResponse(
            message.data as FirebaseMessageData,
            message.messageId
          )
          setNotificationsList((prev) => [notification, ...prev])
          dispatch(
            setUnreadNotificationsAmount(
              message.data?.badge_count ? +message.data.badge_count : 0
            )
          )
        }
      })
    }
  }, [user.uuid])

  const loadMore = async (args) => {
    try {
      const { data } = await getNotificationsList(isUnreadOnly, args)
      if (args?.offset && Number(args.offset) !== 0) {
        setNotificationsList((prev) => [...prev, ...data.results])
      }
    } finally {
    }
  }

  const { hasNextPage, handleLoadMore } = useInfiniteData({
    total,
    limit,
    loadMore
  })

  const initNotifications = async () => {
    let token = ''
    try {
      setIsLoading(true)
      token = (await getTokenInit()) || ''
      if (token) {
        dispatch(setToken(token))
        await getDevice(token)
      }
      await onGetNotifications()
      await onGetUnreadNotifications()
    } catch (e) {
      if (token) {
        await initDevice(token)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onGetUnreadNotifications = async () => {
    try {
      if (!!cancelToken.current) {
        cancelToken.current.cancel()
      }
      setIsLoading(true)
      cancelToken.current = axios.CancelToken.source()
      const response = await getBadgeCount(cancelToken.current?.token)
      dispatch(setUnreadNotificationsAmount(response.data.badge_count))
    } finally {
      setIsLoading(false)
    }
  }

  const onGetNotifications = async (unreadOnly?: boolean) => {
    try {
      setIsLoading(true)
      const { data } = await getNotificationsList(unreadOnly, { limit })
      setNotificationsList(data.results)
      setTotal(data.count)
    } finally {
      setIsLoading(false)
    }
  }

  const onShowUnreadOnly = async () => {
    setIsUnreadOnly((prev) => !prev)
    await onGetNotifications(!isUnreadOnly)
  }

  const onMarkAllAsRead = async () => {
    try {
      dispatch(setLoading(true))
      await markAllNotificationsAsRead()
      await onGetNotifications(isUnreadOnly)
      await onGetUnreadNotifications()
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onMarkAsRead: MarkAsReadFunc = async (id, data) => {
    try {
      const { data: response } = await markNotificationAsRead(id, data)
      setNotificationsList((prev) =>
        prev.map(
          (i) =>
            ({
              ...i,
              is_read: i.uuid === id ? response.is_read : i.is_read
            } as NotificationItem)
        )
      )
      await onGetUnreadNotifications()
    } finally {
    }
  }

  const onOpenModal = (e: MouseEvent) => {
    e.preventDefault()
    if (isOpen) {
      onCloseModal()
    } else {
      dispatch(setIsModalOpen(true))
    }
  }

  const onCloseModal = () => {
    dispatch(setIsModalOpen(false))
    setIsUnreadOnly(false)
  }

  const onClickOutside = (e) => {
    if (
      !e.target.closest('.notifications-drawer') &&
      !e.target.closest('.notifications-link')
    ) {
      onCloseModal()
    }
  }

  return (
    <>
      <NavLink
        label="Notifications"
        hrefTo=""
        onClick={onOpenModal}
        Icon={NotificationsIcon}
        className="notifications-link row space-between"
      >
        {!!unreadNotifications && (
          <Chips.Menu>{unreadNotifications}</Chips.Menu>
        )}
      </NavLink>
      <Drawer
        onClose={onCloseModal}
        closeIcon={null}
        mask={false}
        width={396}
        title={
          <>
            <div className="notifications-drawer__title">
              Notifications{' '}
              {!!unreadNotifications && (
                <span className="notifications-drawer__counter">
                  ({unreadNotifications})
                </span>
              )}
            </div>
            <div className="flex space-between align-center">
              <div className="notifications-drawer__switch flex align-center">
                <Switch checked={isUnreadOnly} onChange={onShowUnreadOnly} />
                <Typography.Body1>Unread only</Typography.Body1>
              </div>
              <Button onClick={onMarkAllAsRead} type={BUTTON_TYPES.LINK}>
                {BTN_TXT.MARK_ALL_AS_READ}
              </Button>
            </div>
          </>
        }
        className="notifications-drawer"
        visible={isOpen}
        contentWrapperStyle={{ position: 'absolute' }}
        placement="left"
      >
        <InfiniteList<NotificationItem>
          items={notificationsList}
          loader={<Spin size="large" indicator={<LoadingOutlined spin />} />}
          renderItem={({ item }) => {
            const itemIdx = notificationsList.findIndex(
              (i) => i.uuid === item.uuid
            )
            return (
              <NotificationItemList
                onMarkAsRead={onMarkAsRead}
                data={item}
                showDate={
                  itemIdx === 0 ||
                  getIsFirstOfTheDay(item, notificationsList[itemIdx - 1])
                }
              />
            )
          }}
          loading={isLoading}
          keyExtractor={(item) => item.uuid}
          hasNextPage={hasNextPage}
          onLoadMore={handleLoadMore}
          listEmptyComponent={
            <div className="notifications-drawer__empty column align-center justify-center">
              <EmptyBox />
              <Typography.Headline6
                className="notifications-drawer__empty-msg"
                weight={TYPOGRAPHY_WEIGHT.BOLD}
              >
                No notifications
              </Typography.Headline6>
            </div>
          }
        />
      </Drawer>
    </>
  )
}
