import { useEffect, useMemo, useRef, useState } from 'react'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import axios, { CancelTokenSource } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'hooks/hooks'
import { getUser } from 'redux/store/user/getters'
import { setLoading } from 'redux/store/common/slice'
import { getAvailableForEvaluationList } from '../../Contracts/api'
import { Table } from 'components/Table'
import { isTableHasParams } from 'helper/common'
import {
  TAvailableForEvaluationList,
  TAvailableForEvaluationListFilters,
  TAvailableForEvaluationListFiltersFunc,
  TAvailableForEvaluationListParams,
  TAvailableForEvaluationRequestData
} from './types'
import { columns } from './constants'
import './style.scss'
import { EvaluateModal } from './EvaluateModal'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { useCognusRepositoryPopup } from '../Providers/CognusRepositoryPopupProvider'
import { useContractPageContext } from '../../../pages/Contracts/Providers/ContractPageContextProvider'
import { ROLES } from '../../Permission'

export const AvailableForEvaluation = () => {
  const [tableData, setTableData] = useState<TAvailableForEvaluationList[]>([])
  const [searchValue, _setSearchValue] = useState('')
  const [sortParams, setSortParams] =
    useState<TAvailableForEvaluationRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState<
    TAvailableForEvaluationList[]
  >([])
  const [filters, setFilters] = useState<TAvailableForEvaluationListFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const debouncedSearch = useDebounce(searchValue, 500)
  const user = useSelector(getUser)

  const { evaluateContractPopup } = useCognusRepositoryPopup()
  const { setEvaluateData } = useContractPageContext().actions
  const { evaluateData } = useContractPageContext().state
  const { actions } = evaluateContractPopup

  const canEvaluate = useMemo(() => {
    return Boolean(
      user.role === ROLES.VICE_PRESIDENT || user.role === ROLES.CONTRACT_STEWARD
    )
  }, [user])

  const contractIdsForEvaluation = useMemo(() => {
    const resultedData = [] as string[]
    tableData.forEach((item) => {
      if (evaluateData.includes(item.contract_category_id)) {
        item.led_by?.forEach((ledByOption) => {
          resultedData.push(ledByOption.uuid)
        })
      }
    })
    return resultedData
  }, [evaluateData, tableData])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, ...filters, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const handleSelectRow = (selectedRowKeys, selectedRow) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRow)
  }

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TAvailableForEvaluationRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TAvailableForEvaluationListParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getAvailableForEvaluationList({
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.contract_category_id
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const applyFilters: TAvailableForEvaluationListFiltersFunc = (
    field,
    appliedFilters
  ) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }

  const callbackAfterEvaluation = () => {
    actions.close()
    setSelectedRowKeys([])
    setSelectedRows([])
    setFilters({})
    getTableData({ page: { ...DEFAULT_PAGE } })
  }

  const handleEvaluate = () => {
    setEvaluateData(selectedRowKeys)
    actions.open()
  }

  return (
    <div className="table-wrapper community-users available-for-evaluation mt-8">
      <div className="table-wrapper__header">
        {!!selectedRows.length && (
          <div className="column">
            <Typography.Body1
              className="table-wrapper__select-title"
              weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
            >
              Selected {selectedRowKeys.length} of {totalNumber}
            </Typography.Body1>
            <Typography.Caption className="page-number">
              {`Page ${pageInfo.pageNumber} of ${Math.ceil(
                totalNumber / pageInfo.pageSize
              )}`}
            </Typography.Caption>
          </div>
        )}
        <div className="table-wrapper__header-buttons">
          {!!selectedRowKeys.length && canEvaluate && (
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={handleEvaluate}
              upperCase
            >
              {BTN_TXT.EVALUATE}
            </Button>
          )}
        </div>
      </div>
      {!firstLoad && (
        <Table<TAvailableForEvaluationList>
          dataSource={tableData}
          columns={columns({
            applyFilters,
            filters,
            user: user
          })}
          className="contracts-table contracts-page"
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<TAvailableForEvaluationListFilters>(
            debouncedSearch,
            filters
          )}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          rowSelection={
            canEvaluate
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow,
                  preserveSelectedRowKeys: true
                }
              : undefined
          }
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
        />
      )}
      <EvaluateModal
        callback={callbackAfterEvaluation}
        contractIdsForEvaluation={contractIdsForEvaluation}
      />
    </div>
  )
}
