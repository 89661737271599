import { Fragment, memo } from 'react'
import { Form, Radio, Space } from 'antd'
import { Select } from '../../../../../components/Select'
import { Input } from '../../../../../components/Input'
import { Button, BUTTON_TYPES } from '../../../../../components/Button'
import { Typography } from '../../../../../components/Typography'
import useRebateConditionsForm from '../hooks/useRebateConditionsForm'
import { minRebateValueValidator, percentageRebateValidator } from '../utils'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Clos16 } from 'assets/svg/Clos16.svg'
import { BTN_TXT, LABELS, PLACEHOLDERS } from '../../../../../constants'
import { MONTH_OPTIONS, PROPS_INPUT_NUMBER } from '../constants'
import { DEFAULT_HOSPITAL_VALUES, HOSPITAL_OPTIONS } from './constants'
import { TOption } from '../../../../../components/Select/types'
import { MarketShareRebateConditionProps } from './types'
import './styles.scss'

const MarketShareRebateCondition = ({
  applyToHospitals,
  handleChangeForm,
  hospitalRebates
}: MarketShareRebateConditionProps) => {
  const { hospitals } = useRebateConditionsForm()
  const selectedHospitals = hospitalRebates?.filter((i) => !!i?.hospital) || []

  const getFilteredHospitals = (options: TOption[], idx: number) =>
    options.filter(
      (item) =>
        !(
          selectedHospitals?.find((i) => i?.hospital === item.value) &&
          selectedHospitals?.[idx]?.hospital !== item.value
        )
    )

  return (
    <div className="market-share-rebate column gap-16">
      <div className="row gap-12">
        <Input.Number
          propsItem={{
            name: 'baseline_spend',
            label: LABELS.BASELINE_SPEND,
            rules: [
              {
                required: applyToHospitals,
                validator: applyToHospitals
                  ? minRebateValueValidator
                  : undefined
              }
            ]
          }}
          propsInputNumber={{
            ...PROPS_INPUT_NUMBER,
            disabled: !applyToHospitals
          }}
        />
        <Input.Number
          propsItem={{
            name: 'amount',
            label: LABELS.REBATE_AMOUNT,
            rules: [
              {
                validator: percentageRebateValidator
              }
            ]
          }}
          propsInputNumber={PROPS_INPUT_NUMBER}
        />
      </div>
      <Select
        options={MONTH_OPTIONS}
        propsItem={{
          name: 'period_threshold',
          label: LABELS.PERIOD_THRESHOLD
        }}
        propsSelect={{
          placeholder: PLACEHOLDERS.PLEASE_SELECT,
          onChange: handleChangeForm
        }}
      />
      <Form.Item
        label={LABELS.APPLY_TO_ALL_HOSPITALS}
        name="apply_to_all_hospitals"
        className="market-share-rebate__hospital-option"
        colon={false}
      >
        <Radio.Group>
          <Space direction="vertical">
            {HOSPITAL_OPTIONS.map((i) => (
              <Radio key={i.label} value={i.value}>
                <Typography.Body1>{i.label}</Typography.Body1>
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      {!applyToHospitals && (
        <Form.List name="hospitals_rebates">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...rest }, idx) => (
                <Fragment key={key}>
                  <div className="market-share-rebate__hospital-rebate">
                    <Select
                      options={getFilteredHospitals(hospitals, idx)}
                      propsItem={{
                        ...rest,
                        name: [name, 'hospital'],
                        label: LABELS.HOSPITAL,
                        rules: [
                          {
                            required: true,
                            message: 'Hospital is required'
                          }
                        ]
                      }}
                      propsSelect={{
                        placeholder: PLACEHOLDERS.PLEASE_SELECT,
                        onChange: handleChangeForm
                      }}
                    />
                    {hospitalRebates.length > 1 && (
                      <Button.Icon
                        type={BUTTON_TYPES.GHOST}
                        icon={<Clos16 />}
                        className="market-share-rebate__remove-hospital-btn"
                        onClick={() => {
                          remove(name)
                          handleChangeForm()
                        }}
                      />
                    )}
                  </div>
                  <div className="row two-columns">
                    <Input.Number
                      propsItem={{
                        ...rest,
                        name: [name, 'baseline_spend'],
                        label: LABELS.HOSPITAL_BASELINE_SPEND,
                        rules: [
                          {
                            validator: minRebateValueValidator
                          }
                        ]
                      }}
                      propsInputNumber={PROPS_INPUT_NUMBER}
                    />
                  </div>
                </Fragment>
              ))}
              {hospitalRebates.length !== hospitals.length && (
                <Button
                  className="market-share-rebate__add-btn"
                  type={BUTTON_TYPES.GHOST}
                  icon={<Plus16 />}
                  onClick={() => {
                    add({ ...DEFAULT_HOSPITAL_VALUES })
                    handleChangeForm()
                  }}
                  upperCase
                >
                  {BTN_TXT.ADD_HOSPITAL}
                </Button>
              )}
            </>
          )}
        </Form.List>
      )}
    </div>
  )
}

export default memo(MarketShareRebateCondition)
