import Popup from '../../components/Popup/Popup'
import Button from '../../components/Button/Button'
import { BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT } from '../../constants'
import Typography from '../../components/Typography/Typography'
import { useContractDetailsPopup } from '../ContractDetails/Providers/ContractDetailsPopupProvider'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/store/common/slice'
import { setSendForApproval } from './api'
import { useContractDetailsContext } from '../ContractDetails/Providers/ContractDetailsContextProvider'
import { notification } from '../../components/Notification'
type TProps = {
  callback: () => void
}
export const SendForApprovalModal = ({ callback }: TProps) => {
  const dispatch = useDispatch()
  const details = useContractDetailsContext()
  const { sendForApprovalPopup } = useContractDetailsPopup()
  const { actions, state } = sendForApprovalPopup

  const onClick = async () => {
    dispatch(setLoading(true))
    try {
      await setSendForApproval(details.state.details.uuid)
      notification.success({
        message: `Items list has been successfully moved to Contract Owner for approval`
      })
      callback()
      details.actions.getPendingItemStateAsync()
    } catch (e) {
      console.error(e)
    } finally {
      actions.close()
      dispatch(setLoading(false))
    }
  }
  return (
    <Popup
      {...state}
      onCancel={actions.close}
      width={442}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={actions.close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button type={BUTTON_TYPES.PRIMARY} onClick={onClick} upperCase>
            {BTN_TXT.SEND}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        Are you sure you would like to send item list for approval?
      </Typography.Body1>
    </Popup>
  )
}
