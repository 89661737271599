import { useEffect, useMemo } from 'react'
import { Form } from 'antd'
import StraightRebateCondition from 'features/ContractDetails/RebateConditions/RebateConditionsForm/StraightRebateCondition/StraightRebateCondition'
import GrowthRebateCondition from 'features/ContractDetails/RebateConditions/RebateConditionsForm/GrowthRebateCondition/GrowthRebateCondition'
import RebateConditionsFormProvider from 'features/ContractDetails/RebateConditions/RebateConditionsForm/Providers/RebateConditionsFormProvider'
import MarketShareRebateCondition from './MarketShareRebateCondition/MarketShareRebateCondition'
import { Popup } from '../../../../components/Popup'
import { Select } from 'components/Select'
import { Button, BUTTON_TYPES } from 'components/Button'
import usePopup from '../../../../hooks/usePopup'
import useRebateConditionsForm from 'features/ContractDetails/RebateConditions/RebateConditionsForm/hooks/useRebateConditionsForm'
import { useShowPopup } from '../../../../hooks/useShowPopup'
import { useContractDetailsContext } from '../../Providers/ContractDetailsContextProvider'
import { BTN_TXT, LABELS, PLACEHOLDERS } from 'constants/txt'
import {
  REBATE_CONDITIONS_TYPE,
  REBATE_CONDITIONS_TYPE_OPTIONS
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { IRebateConditionForm } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/types'
import './styles.scss'

const RebateConditionsForm = () => {
  const rebateConditionsForm = useRebateConditionsForm()

  const contractDetailsContext = useContractDetailsContext()
  const { setActiveTab } = contractDetailsContext.actions
  const discardChangesPopup = usePopup()

  const {
    form,
    type,
    hasFormChanges,
    handleFinishForm,
    handleCancelForm,
    handleChangeForm,
    isChanged,
    hospitalRebates,
    applyToHospitals,
    setIsChanged
  } = rebateConditionsForm

  const [showPopup, confirmNavigation, cancelNavigation] =
    useShowPopup(isChanged)

  const cancelDiscardChanges = () => {
    discardChangesPopup.actions.close()
  }

  useEffect(() => {
    if (!showPopup) {
      setActiveTab('rebate_conditions')
    }
  }, [showPopup])

  const RebateConditionType = useMemo(
    () => (
      <Select
        options={REBATE_CONDITIONS_TYPE_OPTIONS}
        propsItem={{
          label: LABELS.REBATE_TYPE,
          name: 'rebate_type',
          rules: [
            {
              required: true
            }
          ]
        }}
        propsSelect={{
          placeholder: PLACEHOLDERS.PLEASE_SELECT,
          onChange: handleChangeForm
        }}
      />
    ),
    [handleChangeForm]
  )

  const rebateCondition = useMemo(() => {
    switch (type) {
      case REBATE_CONDITIONS_TYPE.STRAIGHT_REBATE:
        return <StraightRebateCondition handleChangeForm={handleChangeForm} />
      case REBATE_CONDITIONS_TYPE.GROWTH_REBATE:
        return <GrowthRebateCondition handleChangeForm={handleChangeForm} />
      case REBATE_CONDITIONS_TYPE.MARKET_SHARE_REBATE:
        return (
          <MarketShareRebateCondition
            applyToHospitals={applyToHospitals}
            hospitalRebates={hospitalRebates}
            handleChangeForm={handleChangeForm}
          />
        )
      default:
        return null
    }
  }, [type, applyToHospitals, hospitalRebates, handleChangeForm])

  const FormActions = useMemo(
    () => (
      <div className="rebate-conditions-form__actions row justify-end gap-16">
        <Button onClick={discardChangesPopup.actions.open} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          form="rebate-conditions-form-id"
          htmlType="submit"
          upperCase
        >
          {BTN_TXT.SAVE}
        </Button>
      </div>
    ),
    [handleCancelForm]
  )

  const submitCancellation = () => {
    handleCancelForm()
    discardChangesPopup.actions.close()
    setIsChanged(false)
  }

  return (
    <RebateConditionsFormProvider {...rebateConditionsForm}>
      <Form<IRebateConditionForm>
        id="rebate-conditions-form-id"
        className="rebate-conditions-form column gap-16"
        form={form}
        onChange={handleChangeForm}
        onFinish={handleFinishForm}
        onFieldsChange={() => setIsChanged(true)}
      >
        {RebateConditionType}
        {rebateCondition}
        {hasFormChanges && FormActions}
      </Form>
      <Popup.Discard
        visible={showPopup ? showPopup : discardChangesPopup.state.visible}
        onCancel={showPopup ? cancelNavigation : cancelDiscardChanges}
        onSubmit={showPopup ? confirmNavigation : submitCancellation}
      />
    </RebateConditionsFormProvider>
  )
}

export default RebateConditionsForm
