import { useCallback, useMemo } from 'react'
import cn from 'classnames'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import useRebateConditionsForm from '../RebateConditionsForm/hooks/useRebateConditionsForm'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { safeNumber } from 'helper/common'
import {
  REBATE_CONDITIONS_TYPE,
  REBATE_CONDITIONS_TYPE_NAME
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { LABELS } from 'constants/txt'
import './styles.scss'

const formatCurrency = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol'
})

const formatPercent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const RebateConditionsPreview = () => {
  const contractDetailsContext = useContractDetailsContext()
  const { hospitals } = useRebateConditionsForm()

  const { details } = contractDetailsContext.state

  const straightRebateCondition = useCallback(
    (rebate) => (
      <div className="row space-between gap-24">
        <FieldWithLabel title="Rebate amount, %">
          {formatPercent.format(safeNumber(rebate.amount) / 100)}
        </FieldWithLabel>
        <FieldWithLabel title="Period threshold, months">
          {rebate.period_threshold}
        </FieldWithLabel>
      </div>
    ),
    []
  )

  const growthRebateCondition = useCallback(
    (rebate) => (
      <div className="column gap-24">
        <div className="row space-between gap-24">
          <FieldWithLabel title="Baseline spend, $">
            {formatCurrency.format(safeNumber(rebate.baseline_spend))}
          </FieldWithLabel>
          <FieldWithLabel title="Rebate amount, %">
            {formatPercent.format(safeNumber(rebate.amount) / 100)}
          </FieldWithLabel>
        </div>
        <FieldWithLabel title="Period threshold, months">
          {rebate.period_threshold}
        </FieldWithLabel>
      </div>
    ),
    []
  )

  const marketShareRebateCondition = useCallback(
    (rebate) => (
      <div className="column gap-32">
        <div className="row space-between gap-24">
          <FieldWithLabel title="Baseline spend, $">
            {formatCurrency.format(safeNumber(rebate.baseline_spend))}
          </FieldWithLabel>
          <FieldWithLabel title="Rebate amount, %">
            {formatPercent.format(safeNumber(rebate.amount) / 100)}
          </FieldWithLabel>
          <FieldWithLabel title="Period threshold, months">
            {rebate.period_threshold}
          </FieldWithLabel>
        </div>
        {!!rebate.hospitals_rebates?.length && (
          <div className="column gap-24">
            <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.BOLD}>
              Individual Hospital rebates
            </Typography.Headline6>
            {rebate.hospitals_rebates.map((rebate, idx) => (
              <div className="row space-between gap-24" key={idx}>
                <FieldWithLabel title="Hospital">
                  {hospitals.find((i) => i.value === rebate.hospital)?.label}
                </FieldWithLabel>
                <FieldWithLabel
                  className="rebate-conditions-preview__hospital-spend"
                  title="Hospital baseline spend, $"
                >
                  {formatCurrency.format(safeNumber(rebate.baseline_spend))}
                </FieldWithLabel>
              </div>
            ))}
          </div>
        )}
      </div>
    ),
    [hospitals]
  )

  const rebateCondition = useCallback(
    (rebate) => {
      switch (details.rebate_type) {
        case REBATE_CONDITIONS_TYPE.NO_REBATE:
          return null
        case REBATE_CONDITIONS_TYPE.STRAIGHT_REBATE:
          return straightRebateCondition(rebate)
        case REBATE_CONDITIONS_TYPE.GROWTH_REBATE:
          return growthRebateCondition(rebate)
        case REBATE_CONDITIONS_TYPE.MARKET_SHARE_REBATE:
          return marketShareRebateCondition(rebate)
      }
    },
    [
      details.rebate_type,
      straightRebateCondition,
      growthRebateCondition,
      marketShareRebateCondition
    ]
  )

  const RebateCondition = useMemo(
    () => rebateCondition(details.rebate),
    [details, rebateCondition]
  )

  return (
    <div
      className={cn('rebate-conditions-preview column gap-24', {
        'rebate-conditions-preview--wide':
          details.rebate_type === REBATE_CONDITIONS_TYPE.MARKET_SHARE_REBATE
      })}
    >
      <FieldWithLabel title={LABELS.REBATE_TYPE}>
        <Typography.Body1>
          {REBATE_CONDITIONS_TYPE_NAME[details.rebate_type]}
        </Typography.Body1>
      </FieldWithLabel>
      {RebateCondition}
    </div>
  )
}

export default RebateConditionsPreview
