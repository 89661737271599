import { useContractDetailsPopup } from '../../ContractDetails/Providers/ContractDetailsPopupProvider'
import { Popup } from '../../../components/Popup'
import { Banner } from '../../../components/Banner/Banner'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT, TOOLTIP } from '../../../constants'
import { Table } from '../../../components/Table'
import {
  TContractItemsListParams,
  TContractItemsListRecord,
  TContractItemsRequestData,
  TReconciliationDecision
} from '../types'
import { ReactComponent as ArrowRight16 } from 'assets/svg/ArrowRight16.svg'
import { ReactComponent as ArrowDown16 } from 'assets/svg/ArrowDown16.svg'
import { ReactComponent as InformationSvg } from 'assets/svg/WarningIcon.svg'
import { useContractDetailsContext } from '../../ContractDetails/Providers/ContractDetailsContextProvider'
import { useEffect, useRef, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import { setLoading } from '../../../redux/store/common/slice'
import {
  getReconciliationItemsTableData,
  resolveReconciliationItems
} from '../api'
import { useDispatch } from 'react-redux'
import { ColumnsType } from 'antd/es/table'
import { Tooltip } from '../../../components/Tooltip/Tooltip'
import { CheckboxProps } from 'antd/lib/checkbox/Checkbox'
import cn from 'classnames'
import { mapData } from './helper'
import { notification } from '../../../components/Notification'

const ReconcileDuplicatesPopup = () => {
  const { reconcileDuplicatesPopup } = useContractDetailsPopup()
  const { state, actions } = reconcileDuplicatesPopup
  const contractDetailsContext = useContractDetailsContext()
  const { details } = contractDetailsContext.state
  const { getContractDetailsAsync, getPendingItemStateAsync } =
    contractDetailsContext.actions
  const dispatch = useDispatch()

  const tableDataTokenRef = useRef<CancelTokenSource>()

  const [tableData, setTableData] = useState<TContractItemsListRecord[]>([])
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [columns, setColumns] = useState<ColumnsType<any>>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState<TContractItemsListRecord[]>(
    []
  )
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const handleSelectRow = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }

  useEffect(() => {
    if (state.visible) {
      getTableData({})
    }
  }, [state.visible, details.pending_reconciliation?.uuid])

  const getTableData = ({ page }: TContractItemsRequestData) => {
    if (!details.pending_reconciliation?.uuid) return
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TContractItemsListParams = {}
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    details.uuid &&
      getReconciliationItemsTableData(
        {
          cancelToken: tableDataTokenRef.current?.token,
          params
        },
        details.uuid,
        details.pending_reconciliation?.uuid
      )
        .then((resp) => {
          if (!resp.data?.reconcile_items) {
            return
          }

          const headerEntries = Object.keys(resp.data.header) as string[]

          const mappedData = resp.data.reconcile_items?.map((item) => {
            const children: any = [
              ...item.contract_items.map((contractItem) => ({
                ...contractItem.record,
                key: contractItem.uuid,
                isExisted: true,
                is_pre_loaded: false,
                uuid: item.uuid
              })),
              ...item.pre_loaded_contract_items.map((preLoadedItem) => ({
                ...preLoadedItem.record,
                key: preLoadedItem.uuid,
                isExisted: false,
                is_pre_loaded: true,
                uuid: item.uuid
              }))
            ]
            const name = children[0]?.supplier_item_number || ''
            return {
              name: name,
              uuid: item.uuid,
              key: item.uuid,
              children: children
            }
          })

          const dataWithDiffKeys = mapData(mappedData)
          const returnedColumns: ColumnsType<any> = headerEntries.map(
            (headerKey: string, idx: number) => ({
              dataIndex: headerKey,
              key: headerKey,
              width: 200,
              title: resp.data.header[headerKey],
              showSorterTooltip: false,
              className: headerKey + '-row',
              onCell: (record) => ({
                colSpan: idx === 0 && record.name ? 2 : 1
              }),
              render: (text, record) => {
                const current = dataWithDiffKeys.find(
                  (item) => item.key === record?.uuid && !record?.children
                )?.diffFields

                return (
                  <div
                    className={cn('td-custom-class', {
                      'diff-cells': current?.includes(headerKey)
                    })}
                  >
                    {!record?.children ? (!text ? '-' : text) : ''}
                  </div>
                )
              }
            })
          )
          setColumns(
            returnedColumns.filter(
              (column) => column.key !== 'supplier_item_number'
            )
          )

          setTableData(mappedData)
          setTotalNumber(resp.data.reconcile_items.length)
        })
        .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      page
    })
    setPageInfo(page)
  }
  const onResolve = async () => {
    if (!details.pending_reconciliation) return
    dispatch(setLoading(true))
    const resolvedData: TReconciliationDecision[] = selectedRows.map(
      (selected) => {
        return {
          reconcile_item: String(selected?.uuid) || '',
          pre_loaded_contract_item: selected?.is_pre_loaded
            ? String(selected.key)
            : '',
          contract_item: !selected?.is_pre_loaded ? String(selected.key) : ''
        }
      }
    )
    try {
      await resolveReconciliationItems(
        details.uuid,
        details.pending_reconciliation?.uuid,
        resolvedData
      )
      notification.success({
        message: `New record${
          selectedRowKeys?.length > 1 ? 's have' : ' has'
        } been added to the existing item list`
      })
      actions.close()
      getContractDetailsAsync()
      getPendingItemStateAsync()
      getTableData({})
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <Popup
      {...state}
      onCancel={actions.close}
      width={1376}
      footer={
        <>
          <Button onClick={actions.close} type={BUTTON_TYPES.DEFAULT} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            disabled={selectedRowKeys.length !== tableData.length}
            onClick={onResolve}
            type={BUTTON_TYPES.PRIMARY}
            upperCase
          >
            {BTN_TXT.SAVE}
          </Button>
        </>
      }
    >
      <Banner
        className="mb-20"
        text={`During the upload, we found ${tableData?.length} duplicate${
          tableData?.length > 1 ? '' : 's'
        } based on the Supplier Item Number. Please select the correct row from each group to proceed.`}
      />
      <Table<TContractItemsListRecord>
        dataSource={tableData}
        className="contacts-duplicates-item-list-table"
        columns={columns}
        onChange={handleTableChange}
        scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
        onChangePage={handleChangePageSize}
        pageSize={pageInfo.pageSize}
        pagination={{
          pageSize: pageInfo.pageSize,
          current: pageInfo.pageNumber,
          total: totalNumber
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: handleSelectRow,
          hideSelectAll: true,
          renderCell: (checked, record, _, node) => {
            if (
              !checked &&
              selectedRows.find((i) => i.vendor_name === record.vendor_name) &&
              !selectedRowKeys.find((i) => i === record.uuid)
            ) {
              return (
                <div className="row gap-12 align-baseline">
                  {record.isExisted ? (
                    <Tooltip
                      title={'Record already exists in the item list'}
                      key={record.key}
                    >
                      <div className="existed-record-info-icon">
                        <InformationSvg />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="existed-record-info-icon" />
                  )}
                  <Tooltip
                    title={
                      TOOLTIP.ONLY_ONE_OPTION_PER_DUPLICATE_GROUP_IS_SELECTABLE
                    }
                    overlayInnerStyle={{
                      display: 'block',
                      width: 254
                    }}
                    placement="topLeft"
                  >
                    {node}
                  </Tooltip>
                </div>
              )
            }
            return (
              <div className="row gap-12 align-baseline">
                {' '}
                {record.isExisted ? (
                  <Tooltip title={'Record already exists in the item list'}>
                    <div className="existed-record-info-icon">
                      <InformationSvg />
                    </div>
                  </Tooltip>
                ) : (
                  <div className="existed-record-info-icon" />
                )}
                {node}
              </div>
            )
          },
          getCheckboxProps: (record) => {
            const checkboxProps: CheckboxProps = {}
            if (
              selectedRows.find(
                (i) => i.supplier_item_number === record.supplier_item_number
              ) &&
              !selectedRowKeys.find((i) => i === record.key)
            ) {
              checkboxProps.disabled = true
            }
            return checkboxProps
          }
        }}
        expandable={{
          defaultExpandAllRows: true,
          columnWidth: 200,
          expandIcon: ({ expanded, onExpand, record, expandable }) =>
            expandable ? (
              expanded ? (
                <div
                  className="expand-wrapper row gap-12 align-center"
                  onClick={(e: any) => onExpand(record, e)}
                >
                  <ArrowDown16 />
                  <div>{record.name}</div>
                </div>
              ) : (
                <div
                  className="expand-wrapper row gap-12 align-center"
                  onClick={(e: any) => onExpand(record, e)}
                >
                  <ArrowRight16 />
                  <div>{record.name}</div>
                </div>
              )
            ) : (
              <></>
            )
        }}
      />
    </Popup>
  )
}

export default ReconcileDuplicatesPopup
