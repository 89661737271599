import { useCallback, useEffect, useState } from 'react'
import { Form } from 'antd'

import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { getHospitalsList } from '../MarketShareRebateCondition/api'
import {
  convertDetailsToForm,
  convertFormToRequestData
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/utils'
import { INITIAL_REBATE_CONDITIONS } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { DEFAULT_HOSPITAL_VALUES } from '../MarketShareRebateCondition/constants'
import { IRebateConditionForm } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/types'
import { TOption } from '../../../../../components/Select/types'

const useRebateConditionsForm = () => {
  const contractDetailsContext = useContractDetailsContext()
  const [isChanged, setIsChanged] = useState(false)
  const [hospitals, setHospitals] = useState<TOption[]>([])

  const { details } = contractDetailsContext.state
  const { updateContractRebatesAsync } = contractDetailsContext.actions

  const [form] = Form.useForm<IRebateConditionForm>()

  const type = Form.useWatch('rebate_type', form)
  const applyToHospitals = !!Form.useWatch('apply_to_all_hospitals', form)
  const hospitalRebates = Form.useWatch('hospitals_rebates', form) || []

  const [hasFormChanges, _setHasFormChanges] = useState(false)
  const [hasValidationError, _setHasValidationError] = useState(false)

  const triggerHasFormChanges = useCallback(() => {
    if (!hasFormChanges) {
      _setHasFormChanges(true)
    }
    if (hasValidationError) {
      _setHasValidationError(false)
    }
  }, [hasFormChanges, hasValidationError])

  const onGetHospitals = async () => {
    try {
      const { data } = await getHospitalsList(details.uuid)
      setHospitals(
        data.results.map((i) => ({
          value: i.uuid,
          label: i.name
        }))
      )
    } finally {
    }
  }

  useEffect(() => {
    if (details.uuid) {
      onGetHospitals()
    }
  }, [details.uuid])

  useEffect(() => {
    if (applyToHospitals) {
      form.setFieldsValue({ hospitals_rebates: [] })
    } else {
      form.setFieldsValue({
        baseline_spend: null,
        hospitals_rebates: [{ ...DEFAULT_HOSPITAL_VALUES }]
      })
    }
  }, [applyToHospitals])

  const handleFinishForm = useCallback(
    async (values: IRebateConditionForm) => {
      setIsChanged(false)

      const successCallback = () => {
        _setHasFormChanges(false)
      }

      const requestData = convertFormToRequestData(values)

      await updateContractRebatesAsync(requestData, successCallback)
    },
    [updateContractRebatesAsync]
  )

  const handleCancelForm = useCallback(() => {
    form.setFieldsValue(convertDetailsToForm(details))

    _setHasFormChanges(false)
  }, [details, form])

  const handleChangeForm = useCallback(() => {
    triggerHasFormChanges()
  }, [triggerHasFormChanges])

  useEffect(() => {
    if (details.rebate_type === type) {
      form.setFieldsValue(convertDetailsToForm(details))

      return
    }

    form.setFieldsValue({ ...INITIAL_REBATE_CONDITIONS[type] })
  }, [details, form, type])

  useEffect(() => {
    form.setFieldsValue(convertDetailsToForm(details))
  }, [details, form])

  return {
    form,
    type,
    isChanged,
    applyToHospitals,
    hasFormChanges,
    hasValidationError,
    handleFinishForm,
    handleCancelForm,
    handleChangeForm,
    hospitals,
    hospitalRebates,
    setIsChanged
  }
}

export type UseRebateConditionsFormReturnType = ReturnType<
  typeof useRebateConditionsForm
>

export default useRebateConditionsForm
