import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Dropdown } from 'components/Dropdown'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { Sorter } from 'components/Sorter/Sorter'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import {
  TAvailableForEvaluationList,
  TAvailableForEvaluationListColumnsFunc,
  TAvailableForEvaluationListFilters,
  TAvailableForEvaluationListFiltersFunc
} from './types'
import { REBATE_CONDITIONS_TITLE } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import moment from 'moment/moment'
import { timelineDateFormat } from '../../../components/Timelines/constants'
import { LeadByIndicator } from '../../../components/LeadByIndicator'
import { EvaluateContainer } from './EvaluateModal'
import { ROLES } from 'features/Permission'

export const columns = ({
  applyFilters,
  filters,
  user
}: TAvailableForEvaluationListColumnsFunc): ColumnsType<TAvailableForEvaluationList> => {
  const _columns: ColumnsType<TAvailableForEvaluationList> = [
    {
      dataIndex: 'contract_category_name',
      key: 'contract_category_name',
      width: 234,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TAvailableForEvaluationList>
          dataIndex={'contract_category_name'}
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Contract category
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TAvailableForEvaluationListFilters,
          TAvailableForEvaluationListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={fetchContractCategories}
          field="contract_category_name"
          asyncSearch
          {...props}
        />
      ),
      render: (_, { contract_category_name }) => (
        <CellWithSubtitle title={contract_category_name} />
      ),
      filterIcon: !!filters?.contract_category?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'led_by',
      key: 'led_by',
      width: 134,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TAvailableForEvaluationList>
          dataIndex="led_by"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Led by
        </Sorter>
      ),
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts, led_by }) =>
            contracts?.map((_, idx) => (
              <div className="separate-item">
                {idx === 0 && led_by && <LeadByIndicator value={led_by} />}
              </div>
            ))
          )}
        </div>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'vendor',
      key: 'vendor',
      title: 'Vendor',
      width: 233,
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts }) =>
            contracts?.map(({ vendor_name, uuid }) => (
              <div className="separate-item row align-center" key={uuid}>
                {vendor_name}
              </div>
            ))
          )}
        </div>
      )
    },
    {
      dataIndex: 'rebate_type',
      key: 'rebate_type',
      width: 172,
      title: 'Rebate type',
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts }) =>
            contracts?.map(({ rebate_type }) => (
              <div className="separate-item row align-center">
                <CellWithSubtitle
                  title={
                    rebate_type ? REBATE_CONDITIONS_TITLE[rebate_type] : '-'
                  }
                />
              </div>
            ))
          )}
        </div>
      ),
      showSorterTooltip: false
    },
    {
      dataIndex: 'start_date',
      key: 'start_date',
      title: 'Start date',
      width: 160,
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts }) =>
            contracts?.map(({ start_date }) => (
              <div className="separate-item row align-center">
                {start_date
                  ? moment(start_date).format(timelineDateFormat)
                  : '-'}
              </div>
            ))
          )}
        </div>
      ),
      showSorterTooltip: false
    },
    {
      dataIndex: 'finish_date',
      key: 'finish_date',
      title: 'Finish date',
      width: 160,
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts }) =>
            contracts?.map(({ primary_finish_date }) => (
              <div className="separate-item row align-center">
                {primary_finish_date
                  ? moment(primary_finish_date).format(timelineDateFormat)
                  : '-'}
              </div>
            ))
          )}
        </div>
      ),
      showSorterTooltip: false
    },
    {
      dataIndex: 'admin_fee',
      key: 'admin_fee',
      title: 'Admin fee',
      width: 84,
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts }) =>
            contracts?.map(({ admin_fee }) => (
              <div className="separate-item row align-center">{admin_fee}</div>
            ))
          )}
        </div>
      ),
      showSorterTooltip: false
    }
  ]
  if (
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD
  ) {
    _columns.push({
      dataIndex: 'action',
      key: 'action',
      title: 'Action',
      width: 100,
      render: (_, row) => <EvaluateContainer uuid={row.contract_category_id} />,
      showSorterTooltip: false
    })
  }
  return _columns
}
