import { useRef, useState, useEffect } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse as CollapseAntd } from 'antd'
import { Input } from '../../../components/Input'
import { Collapse } from '../../../components/Collapse'
import { RejectedInvoiceDetails } from './RejectedInvoiceDetails/RejectedInvoiceDetails'
import { ExportRejectedInvoices } from './ExportRejectedInvoices/ExportRejectedInvoices'
import { Typography } from '../../../components/Typography'
import { Button } from '../../../components/Button'
import { FloatFooter } from '../../../components/FloatFooter/FloatFooter'
import { useDebounce } from '../../../hooks/hooks'
import { getRejectedInvoices, sendEditedLines } from './api'
import { getChangedLines } from '../../../redux/store/rejectedInvoices/getters'
import { setChangedLines } from '../../../redux/store/rejectedInvoices/slice'
import { setLoading } from '../../../redux/store/common/slice'
import { SORT_OPTIONS } from './constants'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as SortingDefault16 } from 'assets/svg/SortingDefault16.svg'
import { ReactComponent as EmptyLarge } from 'assets/svg/EmptyLarge.svg'
import { PLACEHOLDERS } from '../../../constants'
import { BTN_TXT } from '../../../constants'
import { RejectedInvoice } from './types'
import { TQueryParams } from '../../../components/Table/types'
import './styles.scss'

const { Panel } = CollapseAntd

export const RejectedInvoices = () => {
  const [isActive, setIsActive] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [selectedSort, setSelectedSort] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [activeKey, setActiveKey] = useState('')
  const [rejectedInvoices, setRejectedInvoices] = useState<RejectedInvoice[]>(
    []
  )
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const changedLines = useSelector(getChangedLines)
  const debouncedSearch = useDebounce(searchValue, 500)

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
      setIsActive(false)
    }
  }, [])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    const selectedSortValue = SORT_OPTIONS.find(
      (i) => i.value === selectedSort
    )?.value

    timeoutRef.current = setTimeout(
      () => onGetRejectedInvoices(selectedSortValue || ''),
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch, selectedSort])

  useEffect(() => {
    setIsActive(!!changedLines.length)
  }, [changedLines])

  const onGetRejectedInvoices = async (ordering?: string) => {
    try {
      dispatch(setLoading(true))
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
      const params: TQueryParams = {}
      if (searchValue) {
        params.search = searchValue
      }
      if (ordering) {
        params.ordering = ordering
      }
      tableDataTokenRef.current = axios.CancelToken.source()
      const { data } = await getRejectedInvoices({
        cancelToken: tableDataTokenRef.current?.token,
        params
      })
      setRejectedInvoices(data.results)
      if (firstLoad) {
        setFirstLoad(false)
      }
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onChange = (uuid) => setActiveKey(uuid)

  const onSave = async () => {
    try {
      dispatch(setLoading(true))
      const invoicesIds = new Set<string>()
      rejectedInvoices.forEach((inv) => {
        if (
          inv.lines.find((i) => changedLines.find((j) => j.uuid === i.uuid))
        ) {
          invoicesIds.add(inv.lines[0].document_key)
        }
      })
      const invoicesLines = rejectedInvoices
        .filter((i) => invoicesIds.has(i.lines[0].document_key))
        .flatMap((i) => i.lines)
      const linesToSend = invoicesLines.map((line) => {
        const changedLine = changedLines.find((i) => line.uuid === i.uuid)
        if (changedLine) {
          return changedLine
        }
        return line
      })
      await sendEditedLines(linesToSend)
      await onGetRejectedInvoices()
      onCancel()
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onCancel = () => {
    setIsActive(false)
    dispatch(setChangedLines([]))
  }

  return (
    <div className="table-wrapper rejected-invoices">
      <div className="table-wrapper__header">
        <Input
          className="allow-clear"
          propsInput={{
            allowClear: { clearIcon: <Close16 /> },
            placeholder: PLACEHOLDERS.SEARCH,
            prefix: <Search16 />,
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value)
          }}
        />
        <div className="row gap-8">
          <Button.Menu
            className="create-chat-button"
            icon={<SortingDefault16 />}
            selectedValue={selectedSort}
            options={SORT_OPTIONS.map((i) => ({
              ...i,
              onClick: () => setSelectedSort(i.value)
            }))}
            upperCase
          >
            {BTN_TXT.SORT}
          </Button.Menu>
          <ExportRejectedInvoices />
        </div>
      </div>
      {!rejectedInvoices.length && !firstLoad && (
        <div className="rejected-invoices__empty-state column align-center">
          <EmptyLarge />
          <Typography.Headline6 className="mt-24">
            Great news! You have no rejected invoices.
          </Typography.Headline6>
        </div>
      )}
      {!!rejectedInvoices.length && (
        <Collapse activeKey={activeKey} onChange={onChange}>
          {rejectedInvoices.map((i) => (
            <Panel key={i.invoice_key} header={i.invoice_key}>
              <RejectedInvoiceDetails data={i} />
            </Panel>
          ))}
        </Collapse>
      )}
      <FloatFooter
        isActive={isActive}
        onCancel={onCancel}
        onSave={onSave}
        withConfirm
      />
    </div>
  )
}
