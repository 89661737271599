import { Banner } from '../../../components/Banner/Banner'
import { BTN_TXT } from '../../../constants'
import { useContractDetailsPopup } from '../Providers/ContractDetailsPopupProvider'

const SendForApproval = () => {
  const { sendForApprovalPopup } = useContractDetailsPopup()
  const { actions } = sendForApprovalPopup
  const handleOpenModal = () => {
    actions.open()
  }
  return (
    <Banner
      button={{
        onButtonClick: handleOpenModal,
        buttonText: BTN_TXT.SEND_FOR_APPROVAL
      }}
      text="New rows must be approved. Please click 'Send for Approval' to initiate the approval process."
    />
  )
}

export default SendForApproval
