import { routes } from 'router'

export const USER_PROFILE_BREADCRUMBS = (
  fromCommunity: boolean,
  id?: string
) => [
  {
    href: fromCommunity ? routes.myCommunity : routes.settings,
    children: fromCommunity ? 'My Community' : 'Settings'
  },
  {
    children: id && id !== 'new' ? 'User profile' : 'Create user profile'
  }
]

export const PROFILE_TABS = [
  {
    key: '0',
    label: 'General'
  },
  {
    key: '1',
    label: 'Contract categories'
  }
]
