import { safeNumber } from 'helper/common'
import { REBATE_CONDITIONS_TYPE } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { IContractDetails } from 'features/ContractDetails/types'
import {
  IRebateCondition,
  IRebateConditionForm,
  RebateDataRequest
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/types'

export const convertDetailsToForm = (
  details: IContractDetails
): IRebateConditionForm => {
  let rebate: IRebateCondition = details.rebate ? { ...details.rebate } : null

  if (details.rebate_type === REBATE_CONDITIONS_TYPE.NO_REBATE) {
    rebate = null
  }

  if (details.rebate_type === REBATE_CONDITIONS_TYPE.STRAIGHT_REBATE) {
    rebate = {
      amount: details.rebate?.amount ? +details.rebate.amount : 0,
      period_threshold: details.rebate?.period_threshold
    }
  }

  if (details.rebate_type === REBATE_CONDITIONS_TYPE.GROWTH_REBATE) {
    rebate = {
      amount: details.rebate?.amount ? +details.rebate.amount : 0,
      baseline_spend: details.rebate?.baseline_spend
        ? +details.rebate.baseline_spend
        : 0,
      period_threshold: details.rebate?.period_threshold
    }
  }

  if (details.rebate_type === REBATE_CONDITIONS_TYPE.MARKET_SHARE_REBATE) {
    rebate = {
      amount: details.rebate?.amount ? +details.rebate.amount : 0,
      baseline_spend: details.rebate?.baseline_spend
        ? +details.rebate.baseline_spend
        : null,
      period_threshold: details.rebate?.period_threshold,
      apply_to_all_hospitals: details.rebate?.apply_to_all_hospitals,
      hospitals_rebates: details.rebate?.hospitals_rebates
    }
  }

  return {
    rebate_type: details.rebate_type,
    ...rebate
  }
}

export const convertFormToRequestData = (
  values: IRebateConditionForm
): RebateDataRequest => {
  let rebate: IRebateCondition = { ...values }

  if (values.rebate_type === REBATE_CONDITIONS_TYPE.NO_REBATE) {
    rebate = null
  }

  if (values.rebate_type === REBATE_CONDITIONS_TYPE.STRAIGHT_REBATE) {
    rebate = {
      amount: values.amount,
      period_threshold: values.period_threshold
    }
  }

  if (values.rebate_type === REBATE_CONDITIONS_TYPE.GROWTH_REBATE) {
    rebate = {
      amount: values.amount,
      baseline_spend: values.baseline_spend,
      period_threshold: values.period_threshold
    }
  }

  if (values.rebate_type === REBATE_CONDITIONS_TYPE.MARKET_SHARE_REBATE) {
    rebate = {
      amount: values.amount,
      period_threshold: values.period_threshold,
      apply_to_all_hospitals: values.apply_to_all_hospitals
    }
    if (values.baseline_spend) {
      rebate.baseline_spend = values.baseline_spend
    }
    if (!!values.hospitals_rebates?.length) {
      rebate.hospitals_rebates = values.hospitals_rebates
    }
  }

  return {
    rebate_type: values.rebate_type,
    rebate
  }
}

export const minRebateValueValidator = async (_, value) => {
  if (safeNumber(value) < 0.01) {
    return Promise.reject(new Error('Less than 0.01'))
  }

  return Promise.resolve()
}

export const percentageRebateValidator = async (_, value) => {
  await minRebateValueValidator(_, value)

  if (safeNumber(value) > 100) {
    return Promise.reject(new Error('More than 100'))
  }

  return Promise.resolve()
}

export const getMonthOptions = () =>
  Array.from(Array(12).keys()).map((_, idx) => ({
    label: `${idx + 1}`,
    value: idx + 1
  }))
