import cn from 'classnames'
import Typography from '../Typography/Typography'
import { ReactComponent as WarningIcon } from 'assets/svg/WarningIcon.svg'
import { FC } from 'react'
import { BannerProps } from './types'
import './banner.warning.scss'
import { Button, BUTTON_TYPES } from '../Button'

export const Warning: FC<BannerProps> = ({
  text,
  className,
  button,
  subtext
}) => {
  return (
    <div
      className={cn(
        'banner banner--warning row gap-12 align-center space-between',
        className
      )}
    >
      <div className="row gap-12 align-center align-start">
        <WarningIcon />
        <div className="column">
          <Typography.Body1>{text}</Typography.Body1>
          {subtext && (
            <Typography.Caption className="mt-8">{subtext}</Typography.Caption>
          )}
        </div>
      </div>
      {button && (
        <Button
          type={BUTTON_TYPES.GHOST}
          onClick={button.onButtonClick}
          upperCase
        >
          {button.buttonText}
        </Button>
      )}
    </div>
  )
}
