import SendForApproval from './SendForApproval'
import ItemListApproval from './ItemListApproval'
import { DuplicatesBanner } from './DuplicatesBanner'
import { useContractDetailsContext } from '../Providers/ContractDetailsContextProvider'

const ContractBanners = () => {
  const contractDetailsContext = useContractDetailsContext()

  const { details, isPendingBannerVisible, isVendor } =
    contractDetailsContext.state
  return (
    <div className="mb-16 column gap-8">
      {isVendor &&
        isPendingBannerVisible &&
        !details?.pending_reconciliation?.required_items_count && (
          <SendForApproval />
        )}
      {!isVendor && isPendingBannerVisible && <ItemListApproval />}
      {!!details?.pending_reconciliation?.required_items_count && (
        <DuplicatesBanner details={details} />
      )}
    </div>
  )
}
export default ContractBanners
