import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  TContractItemsListRequest,
  TContractItemsListResponse,
  TContractItemsMetadataResponse,
  TContractReconciliationItemsListResponse,
  TReconciliationItemsListRequest,
  TResolveReconciliationRequest,
  TSetDecisionRequest,
  TDownloadCurrentItemListResponse,
  TUploadContractItemsListResponse,
  TUploadContractItemsStatus,
  TCheckCurrentItemListWasGeneratedResponse,
  TMetadataRequest,
  TReplacedHistoryListRequest,
  TContractPendingItemsListRequest
} from './types'

const fetcher = new Fetcher({})

export const getTableMetadata: TMetadataRequest = async (
  uuid: string,
  data
) => {
  return fetcher.request<unknown, TContractItemsMetadataResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACT_ITEMS_METADATA}`,
    method: HTTP_METHODS.GET,
    ...data
  })
}
export const setSendForApproval = async (uuid) =>
  fetcher.request<unknown, TContractItemsMetadataResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACT_ITEMS}${API_DICTIONARY.PENDING}${API_DICTIONARY.SEND_FOR_APPROVAL}`,
    method: HTTP_METHODS.POST
  })
export const setApprovalDecision: TSetDecisionRequest = async (uuid, data) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACT_ITEMS}${API_DICTIONARY.PENDING}${API_DICTIONARY.APPROVAL}`,
    method: HTTP_METHODS.POST,
    data
  })
export const resolveReconciliationItems: TResolveReconciliationRequest = async (
  uuid,
  reconciliationId,
  data
) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACT_ITEMS}${API_DICTIONARY.RECONCILIATION}${reconciliationId}/${API_DICTIONARY.RESOLVE}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getContractItemsTableData: TContractItemsListRequest = async (
  data,
  uuid
) =>
  fetcher.request<unknown, TContractItemsListResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACT_ITEMS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getReplacedHistoryTableData: TReplacedHistoryListRequest = async (
  data,
  uuid,
  replacedId
) =>
  fetcher.request<unknown, TContractItemsListResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACT_ITEMS}${replacedId}/${API_DICTIONARY.HISTORY}`,
    method: HTTP_METHODS.GET,
    ...data
  })
export const getReconciliationItemsTableData: TReconciliationItemsListRequest =
  async (data, uuid, reconciliationId) =>
    fetcher.request<unknown, TContractReconciliationItemsListResponse>({
      url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACT_ITEMS}${API_DICTIONARY.RECONCILIATION}${reconciliationId}/`,
      method: HTTP_METHODS.GET,
      ...data
    })
export const getContractItemsPendingVendorsTableData: TContractPendingItemsListRequest =
  async (data, uuid) =>
    fetcher.request<unknown, TContractItemsListResponse>({
      url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACT_ITEMS}${API_DICTIONARY.PENDING}`,
      method: HTTP_METHODS.GET,
      ...data
    })

export const getContractItemsPendingTableData: TContractItemsListRequest =
  async (data, uuid) =>
    fetcher.request<unknown, TContractItemsListResponse>({
      url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACT_ITEMS}${API_DICTIONARY.AWAITING_APPROVAL}`,
      method: HTTP_METHODS.GET,
      ...data
    })

export const uploadContractItems = async (data, uuid) =>
  fetcher.request<unknown, TUploadContractItemsListResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACT_ITEMS}${API_DICTIONARY.UPLOAD}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })

export const checkCurrentItemListWasGenerated = async (id: string) =>
  fetcher.request<unknown, TCheckCurrentItemListWasGeneratedResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.CONTRACT_ITEMS}${API_DICTIONARY.DOWNLOAD}`,
    method: HTTP_METHODS.GET
  })

export const downloadCurrentItemList = async (id: string) =>
  fetcher.request<unknown, TDownloadCurrentItemListResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.CONTRACT_ITEMS}${API_DICTIONARY.DOWNLOAD}${API_DICTIONARY.GENERATE_FILE}`,
    method: HTTP_METHODS.POST
  })

export const getDownloadCurrentItemListStatus = async ({ id, task_id }) =>
  fetcher.request<unknown, TUploadContractItemsStatus>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.CONTRACT_ITEMS}${API_DICTIONARY.DOWNLOAD}${API_DICTIONARY.GENERATE_FILE}${task_id}`,
    method: HTTP_METHODS.GET
  })
