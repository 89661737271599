export const mapData = (data: any) => {
  return data.map((item) => ({
    key: item.key,
    diffFields: determineDiffChildFields(item.children)
  }))
}

export const determineDiffChildFields = (children: any): string[] => {
  const diffFields: string[] = []
  for (const key of getAllChildrenFields(children)) {
    if (
      !children.every(
        (child) => child[key as string] === children[0][key as string]
      )
    ) {
      diffFields.push(key)
    }
  }
  return diffFields
}

export const getAllChildrenFields = (children: any): string[] => {
  return Array.from(
    new Set(
      children.reduce((acc, child) => {
        return acc.concat(Object.keys(child))
      }, [])
    )
  )
}
