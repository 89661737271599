import { IUser } from 'redux/store/user/types'
import { ContractStatusUnion } from 'features/ContractDetails/types'

export const isOwnerCondition = (user: IUser) => ({
  'creator.uuid': user.uuid
})

export const isInStatuses = (inStatuses: ContractStatusUnion[]) => ({
  status: {
    $in: inStatuses
  }
})

export const isNotInStatuses = (inStatuses: ContractStatusUnion[]) => ({
  status: {
    $nin: inStatuses
  }
})

export const isResponsibleVendorCondition = (user: IUser) => ({
  vendor_responsibles: {
    $elemMatch: { uuid: user.uuid }
  }
})

export const isResponsibleCommunityCondition = (user: IUser) => ({
  community_responsibles: {
    $elemMatch: {
      uuid: user.uuid
    }
  }
})
export const isResponsibleCommonsCondition = (user: IUser) => ({
  commons_responsibles: {
    $elemMatch: {
      uuid: user.uuid
    }
  }
})
