export enum TNotificationType {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info'
}

export type TNotification = {
  id: string
  type?: TNotificationType
  numberOfItems?: number
  date?: string
  messageProps?: {
    itemUuid?: string
    redirectRoute?: string
    specificErrorMessage?: {
      message?: string
      description?: string
    }
  }
}

export enum TNotificationTypeName {
  LOADING_CONTRACTS = 'loadingContracts',
  EXPORT_REJECTED_INVOICES = 'exportRejectedInvoices',
  DOWNLOAD_CURRENT_ITEM_LIST = 'downloadCurrentItemList',
  UPLOAD_ITEM_LIST = 'uploadItemList'
}

export type ContextProps = {
  actions: {
    openNotification: (
      t: TNotification,
      typeName: TNotificationTypeName,
      setNotificationToTheLocalStorage?: boolean
    ) => void
    onCloseNotification: (id: string, typeName: TNotificationTypeName) => void
    onChangeNotification: (
      t: TNotification,
      typeName: TNotificationTypeName
    ) => void
    destroyAllNotifications: () => void
    checkIfProcessIsRunning: (
      uuid: string,
      typeName: TNotificationTypeName
    ) => boolean
  }
}
