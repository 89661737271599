import { useEffect, useState } from 'react'
import { getTableMetadata } from '../api'
import { ColumnsType } from 'antd/es/table'
import { TContractItemsListRecord } from '../types'
import { ReactComponent as InformationSvg } from 'assets/svg/WarningIcon.svg'
import { Tooltip } from '../../../components/Tooltip/Tooltip'
import { mapData } from '../ReconcileDuplicates/helper'
import cn from 'classnames'

export const useColumns = (
  uuid: string,
  tableData: TContractItemsListRecord[],
  reconcile?: boolean,
  compareId?: string
) => {
  const [columns, setColumns] = useState<ColumnsType<any>>([])

  const setColumnsFromRequest = async () => {
    if (!uuid || !tableData.length) return
    getTableMetadata(uuid, {
      params: {
        status: 'pending'
      }
    }).then((resp) => {
      if (!resp.data?.results) {
        return
      }
      const dataWithDiffKeys = mapData(tableData)

      const returnedColumns: ColumnsType<any> = resp.data.results.map(
        (i, idx) => ({
          dataIndex: i.key,
          key: i.key,
          width: 200,
          onCell: (record) => {
            return {
              colSpan: idx === 0 && record.name ? 2 : 1
            }
          },
          title: i.value,
          showSorterTooltip: false,
          className: i.key + '-row',
          render: (text, record) => {
            const current =
              !!dataWithDiffKeys?.length && !record?.children
                ? dataWithDiffKeys[0]?.diffFields
                : []

            return (
              <div
                className={cn('td-custom-class', {
                  'diff-cells': current?.includes(i.key)
                })}
              >
                {!record?.children
                  ? !text
                    ? '-'
                    : text
                  : idx == 0
                  ? record.name
                  : ''}
              </div>
            )
          }
        })
      )
      returnedColumns.unshift({
        dataIndex: 'icon',
        key: 'icon',
        title: '',
        width: 20,
        showSorterTooltip: false,
        className: 'existed-record-info-icon-row',
        render: (_, record) => {
          return (
            <div className="align-center justify-center">
              {(!!reconcile || record.key === compareId) && (
                <Tooltip
                  title={
                    !!reconcile
                      ? 'Record already exists in the item list'
                      : 'Record already existed in the item list and has been replaced'
                  }
                >
                  <div className="existed-record-info-icon">
                    <InformationSvg />
                  </div>
                </Tooltip>
              )}
            </div>
          )
        }
      })
      setColumns(
        returnedColumns.filter(
          (column) => column.key !== 'supplier_item_number'
        )
      )
    })
  }
  useEffect(() => {
    setColumnsFromRequest()
  }, [uuid, tableData])

  return {
    columns
  }
}
