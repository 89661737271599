import Typography from 'components/Typography/Typography'
import ChatDetails from 'features/ChatDetails/ChatDetails'
import { Button } from '../../components/Button'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'
import { getNameInitials, safeString } from 'helper/common'
import { useChatPopup } from '../Chat/Providers/ChatPopupProvider'
import { Can } from '../Chat/Providers/ChatAbilityProvider'
import { ACTIONS, chatSubject, SUBJECTS } from '../Chat/Abilities'
import { TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { ROLES_NAMES } from 'constants/common'
import { BTN_TXT } from '../../constants'
import { IPrivateChatDetails } from 'redux/store/chatDetails/types'

export const PrivateChatDetails = () => {
  const chatContext = useChatContext<IPrivateChatDetails>()
  const { deleteModal } = useChatPopup()

  const { chat } = chatContext.state

  return (
    <ChatDetails
      name={chat?.full_name ? getNameInitials(chat?.full_name) : 'Pc'}
      src={safeString(chat?.avatar?.file)}
      chatName={chat?.full_name}
    >
      {chat?.role && (
        <Typography.Label
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          className="chat-details-container__role"
        >
          {ROLES_NAMES[chat.role]}
        </Typography.Label>
      )}
      {chat?.organization_name && (
        <Typography.Label
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          className="chat-details-container__organization-name"
        >
          {chat.organization_name}
        </Typography.Label>
      )}
      <Can
        I={ACTIONS.DELETE}
        a={chatSubject(SUBJECTS.PRIVATE_CHAT, chatContext.state.chat)}
      >
        <Button
          className="mt-32"
          onClick={deleteModal.actions.open}
          upperCase
          danger
        >
          <Typography.Button weight={TYPOGRAPHY_WEIGHT.MEDIUM}>
            {BTN_TXT.DELETE_CHAT}
          </Typography.Button>
        </Button>
      </Can>
    </ChatDetails>
  )
}
