import { AxiosResponse } from 'axios'
import { FIREBASE_STATUSES } from '../constants'

export const usePolling = () => {
  const poll = async ({
    request,
    successCallback,
    pollingInterval = 5000,
    maxPollingDuration = 300000
  }: {
    request: () => Promise<AxiosResponse<any>>
    successCallback: (data) => void
    pollingInterval?: number
    maxPollingDuration?: number
  }) => {
    const startTime = Date.now() // Record the start time

    const makeRequest = async () => {
      try {
        const data = await request()
        const notificationStatus =
          data.data?.notification_status ?? data.data?.status

        if (notificationStatus !== FIREBASE_STATUSES.IN_PROGRESS) {
          successCallback(data.data)
        }

        const elapsedTime = Date.now() - startTime

        if (
          elapsedTime < maxPollingDuration &&
          notificationStatus === FIREBASE_STATUSES.IN_PROGRESS
        ) {
          setTimeout(makeRequest, pollingInterval) // Schedule next request
        } else {
          console.log('Maximum polling duration reached. Stopping polling.')
        }
      } catch (error) {
        console.error('Error making API request:', error)
        const elapsedTime = Date.now() - startTime

        if (elapsedTime < maxPollingDuration) {
          setTimeout(makeRequest, pollingInterval) // Schedule next request
        } else {
          console.log('Maximum polling duration reached. Stopping polling.')
        }
      }
    }
    makeRequest()
  }

  return {
    poll
  }
}
