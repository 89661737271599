import { useEffect, useState } from 'react'
import { Collapse as CollapseAntd } from 'antd'
import { useDispatch } from 'react-redux'
import Typography from '../../../components/Typography/Typography'
import { Collapse } from '../../../components/Collapse'
import { CommunityDetails } from './CommunityDetails/CommunityDetails'
import { Input } from '../../../components/Input'
import { useDebounce } from '../../../hooks/hooks'
import { setLoading } from '../../../redux/store/common/slice'
import { getCommunitiesList } from './api'
import { PLACEHOLDERS } from '../../../constants'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { Community, CommunitiesSearchParams } from './types'
import './styles.scss'

const { Panel } = CollapseAntd

export const Communities = () => {
  const [searchValue, setSearchValue] = useState('')
  const [activeKey, setActiveKey] = useState<string[]>([])
  const [communities, setCommunities] = useState<Community[]>([])
  const dispatch = useDispatch()

  const debouncedSearch = useDebounce(searchValue, 500)

  useEffect(() => {
    getCommunities({ search: debouncedSearch })
  }, [debouncedSearch])

  const getCommunities = async (params?: CommunitiesSearchParams) => {
    try {
      dispatch(setLoading(true))
      const communitiesResponse = await getCommunitiesList(params)
      setCommunities(communitiesResponse.data.results)
      setActiveKey(communitiesResponse.data.results.map((i) => i.uuid))
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onChange = (id) => setActiveKey(id)

  return (
    <div className="communities-list">
      <Input
        className="communities-list__search allow-clear"
        propsInput={{
          allowClear: { clearIcon: <Close16 /> },
          placeholder: PLACEHOLDERS.SEARCH_BY_HEALTH_SYSTEM,
          prefix: <Search16 />,
          value: searchValue,
          onChange: (e) => setSearchValue(e.target.value)
        }}
      />
      {!!communities.length ? (
        <Collapse activeKey={activeKey} onChange={onChange} accordion={false}>
          {communities.map((i) => (
            <Panel key={i.uuid} header={i.name}>
              <CommunityDetails
                communityId={i.uuid}
                healthSystems={i.health_systems}
              />
            </Panel>
          ))}
        </Collapse>
      ) : (
        <Typography.Headline6 className="row justify-center">
          No Communities found
        </Typography.Headline6>
      )}
    </div>
  )
}
