import { Popup } from '../../../components/Popup'
import { Typography } from '../../../components/Typography'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { useContractDetailsPopup } from '../../ContractDetails/Providers/ContractDetailsPopupProvider'
import { TApproveDeclinePopup } from './types'

const DeclinePopup = ({ itemsCount, callBack }: TApproveDeclinePopup) => {
  const { declineItemListPopup } = useContractDetailsPopup()
  return (
    <Popup
      {...declineItemListPopup.state}
      title={`Decline item${itemsCount > 1 ? 's' : ''}?`}
      width={442}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={declineItemListPopup.actions.close}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            onClick={() => callBack(false)}
            type={BUTTON_TYPES.PRIMARY}
            danger
            upperCase
          >
            {BTN_TXT.DECLINE}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        Declined item{itemsCount > 1 ? 's' : ''} will be deleted. Do you want to
        proceed?
      </Typography.Body1>
    </Popup>
  )
}
export default DeclinePopup
