import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { setLoading } from 'redux/store/common/slice'
import { useDebounce } from 'hooks/hooks'
import { isTableHasParams, validateFileTypes } from 'helper/common'
import { BTN_TXT } from 'constants/txt'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import {
  CONTRACT_ITEM_LIST_TYPES,
  DOCUMENT_TYPE
} from 'components/FileUpload/constants'
import { FileUpload } from 'components/FileUpload/FileUpload'
import { AttachmentsList } from 'components/AttachmentsList/AttachmentsList'
import {
  getContractItemsPendingTableData,
  getContractItemsPendingVendorsTableData,
  getContractItemsTableData,
  setApprovalDecision
} from './api'
import {
  TContractItemsListParams,
  TContractItemsListRecord,
  TContractItemsRequestData,
  TDecision,
  TQueryKeys
} from './types'
import './styles.scss'
import { ReactComponent as Close16 } from '../../assets/svg/Clos16.svg'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from '../ContractDetails/Abilities'
import {
  Can,
  useContractDetailsAbility
} from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'
import { useColumns } from './hooks/useColumns'
import RadioGroup from '../../components/RadioGroup/RadioGroup'
import { ITEM_LIST_TABS } from './constants'
import { SendForApprovalModal } from './SendForApprovalModal'
import CompareRecordPopup from './CompareRecords/CompareRecordPopup'
import { useContractDetailsPopup } from '../ContractDetails/Providers/ContractDetailsPopupProvider'
import { ReactComponent as Download16 } from '../../assets/svg/Download16.svg'
import { ContractUpdateItemsListPopup } from './ContractUpdateItemsListPopup'
import { Button, BUTTON_TYPES } from '../../components/Button'
import classNames from 'classnames'
import { ReactComponent as Upload16 } from '../../assets/svg/Upload16.svg'
import { FieldWithLabel } from '../../components/FieldWithLabel/FieldWithLabel'
import ReconcileDuplicatesPopup from './ReconcileDuplicates/ReconcileDuplicatesPopup'
import { notification } from '../../components/Notification'
import useRouter from '../../hooks/useRouter'
import { useNavigate } from 'react-router-dom'
import { useUploadDownload } from './hooks/useUploadDownload'
import DeclinePopup from './popups/DeclinePopup'
import ApprovePopup from './popups/ApprovePopup'

export const ContractItemsTable = () => {
  const contractDetailsContext = useContractDetailsContext()
  const { location } = useRouter()
  const navigate = useNavigate()
  const [initQueryParams, setInitQueryParams] = useState<TQueryKeys>({})

  const { details, isVendor, isPendingBannerVisible } =
    contractDetailsContext.state

  const {
    updateItemListPopup: { actions }
  } = useContractDetailsPopup()

  const {
    reconcileDuplicatesPopup,
    approveItemListPopup,
    declineItemListPopup,
    compareRecordsPopup
  } = useContractDetailsPopup()

  const [tableData, setTableData] = useState<TContractItemsListRecord[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<TContractItemsRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [activeTab, setActiveTab] = useState('')
  const [replaceOldItems, setReplaceOldItems] = useState(false)
  const [showDownload, setShowDownload] = useState(true)

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const debouncedSearch = useDebounce(searchValue, 500)
  const [compareId, setCompareId] = useState('')

  const openComparePopup = (uuid: string) => {
    setCompareId(uuid)
    compareRecordsPopup.actions.open()
  }
  const isItemsListEmpty = useMemo(() => !tableData?.length, [tableData])

  const { columns } = useColumns(
    details.uuid,
    tableData,
    activeTab,
    openComparePopup
  )

  useEffect(() => {
    if (activeTab === 'current') {
      setShowDownload(tableData.length > 0)
    }
  }, [activeTab, setShowDownload, tableData])

  const { handleUploadFile, disableUploadButton, onDownloadCurrentItemList } =
    useUploadDownload(details, replaceOldItems, isItemsListEmpty)

  const ability = useContractDetailsAbility()

  useEffect(() => {
    if (!ability || !details.uuid) return
    if (
      isVendor &&
      ability.can(ACTIONS.UPLOAD, contractSubject(SUBJECTS.ITEM_LIST, details))
    ) {
      setActiveTab(ITEM_LIST_TABS[1].value)
    } else {
      setActiveTab(ITEM_LIST_TABS[0].value)
    }
  }, [isVendor, ability, details, location])

  const isAbleToApproveDecline = useMemo(() => {
    return Boolean(
      ability.can(
        ACTIONS.UPLOAD,
        contractSubject(SUBJECTS.ITEM_LIST, details)
      ) &&
        !isVendor &&
        activeTab === 'pending'
    )
  }, [activeTab, isVendor, ability, details])

  const getTableData = ({
    sortField,
    sortOrder,
    page
  }: TContractItemsRequestData) => {
    dispatch(setLoading(true))
    if (!activeTab) return
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TContractItemsListParams = {}
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }

    const dataRequest =
      activeTab !== 'pending'
        ? getContractItemsTableData
        : isVendor
        ? getContractItemsPendingVendorsTableData
        : getContractItemsPendingTableData
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    details.uuid &&
      dataRequest(
        {
          cancelToken: tableDataTokenRef.current?.token,
          params
        },
        details.uuid
      )
        .then((resp) => {
          if (!resp.data?.results) {
            return
          }

          setTableData(
            resp.data.results.map((i) => {
              return {
                ...i.record,
                key: i.record?.uuid || i.uuid,
                pending_substatus: i.pending_substatus,
                status: i.pending_substatus
              }
            })
          )
          setTotalNumber(resp.data.count)
          if (firstLoad) {
            setFirstLoad(false)
          }
        })
        .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }

  const handleSelectRow = (selectedRows) => setSelectedRowKeys(selectedRows)

  useEffect(() => {
    setInitQueryParams(Object.fromEntries(new URLSearchParams(location.search)))
  }, [location])

  useEffect(() => {
    if (initQueryParams?.withDuplicatesModal) {
      reconcileDuplicatesPopup.actions.open()
      navigate(location.pathname, { replace: true })
    }
  }, [initQueryParams])

  useEffect(() => {
    setReplaceOldItems(isItemsListEmpty)
  }, [isItemsListEmpty])

  const UploadBtn = () => {
    if (
      (isVendor && activeTab === 'pending') ||
      (!isVendor && activeTab !== 'pending')
    )
      return (
        <div className="table-wrapper__header-buttons">
          {isItemsListEmpty ? (
            <FileUpload
              documentType={DOCUMENT_TYPE.QUESTIONS}
              handleUploadFile={handleUploadFile}
              uploadButtonText={BTN_TXT.UPLOAD_ITEM_LIST}
              uploadBtnText={BTN_TXT.UPLOAD}
              disabled={disableUploadButton}
              uploadFilesProps={{
                multiple: false,
                maxSize: 5242880,
                onDropAccepted: (file) => handleUploadFile(file, 'questions'),
                accept: {
                  ...CONTRACT_ITEM_LIST_TYPES
                },
                validator: (file) =>
                  validateFileTypes(CONTRACT_ITEM_LIST_TYPES, file)
              }}
            />
          ) : (
            <Button
              className={classNames({
                'file-upload__button--is-disabled': false
              })}
              disabled={disableUploadButton}
              type={BUTTON_TYPES.PRIMARY}
              icon={<Upload16 />}
              onClick={actions.open}
              upperCase
            >
              {BTN_TXT.UPDATE_ITEM_LIST}
            </Button>
          )}
        </div>
      )
  }

  const handleActiveTab = (e) => {
    setActiveTab(e?.target?.value)
    setSelectedRowKeys([])
  }

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => getTableData({ ...sortParams }),
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch, details])
  useEffect(() => {
    getTableData({ ...sortParams })
  }, [activeTab])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const handleApproveDeclinePopup = (isApproved: boolean) => {
    if (isApproved) approveItemListPopup.actions.open()
    else declineItemListPopup.actions.open()
  }

  const approveDeclainAction = async (isApproved: boolean) => {
    if (!selectedRowKeys?.length) return
    dispatch(setLoading(true))

    const data: TDecision[] = selectedRowKeys.map((rowId) => ({
      contract_item: rowId,
      decision: isApproved ? 'approved' : 'declined'
    }))
    try {
      await setApprovalDecision(details.uuid, data)
      notification.success({
        message: isApproved
          ? `New record${
              selectedRowKeys?.length > 1 ? 's have' : ' has'
            } been added to the existing item list `
          : `Record${
              selectedRowKeys?.length > 1 ? 's have' : ' has'
            } been declined `
      })
      setSelectedRowKeys([])
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
      if (isApproved) {
        approveItemListPopup.actions.close()
      } else {
        declineItemListPopup.actions.close()
      }
      getTableData({ ...sortParams })
    }
  }

  return (
    <div className="table-wrapper community-users">
      <Can I={ACTIONS.UPLOAD} a={contractSubject(SUBJECTS.ITEM_LIST, details)}>
        <AttachmentsList
          label="Template for new items"
          files={details.contract_document_templates.filter(
            (d) => d.document_type === 'item_list'
          )}
          disableActions
          className="items-list_template"
        />

        {showDownload && (
          <FieldWithLabel title="Item list">
            <div className="attachments-list__files rfp-details-general__files__wrapper__template items-list_template current-item">
              <div className="attachment__content">
                <Button
                  type={BUTTON_TYPES.LINK}
                  icon={<Download16 className="attachment__icon" />}
                  onClick={onDownloadCurrentItemList}
                >
                  {BTN_TXT.DOWNLOAD_CURRENT_ITEM_LIST}
                </Button>
              </div>
            </div>
          </FieldWithLabel>
        )}
      </Can>

      <div className="table-wrapper__header">
        <div className="row">
          <Input
            className="allow-clear"
            propsInput={{
              allowClear: { clearIcon: <Close16 /> },
              placeholder: 'Search',
              prefix: <Search16 />,
              value: searchValue,
              onChange: (e) => setSearchValue(e.target.value)
            }}
          />
          <Can
            I={ACTIONS.UPLOAD}
            a={contractSubject(SUBJECTS.ITEM_LIST, details)}
          >
            <RadioGroup.TimePeriod
              options={ITEM_LIST_TABS.map((tab) =>
                tab.value === 'pending'
                  ? { ...tab, disabled: !isPendingBannerVisible && !isVendor }
                  : tab
              )}
              value={activeTab}
              defaultValue={activeTab}
              onChange={handleActiveTab}
              tooltipWidth="345px"
              className="item-list-tabs ml-16"
            />
          </Can>
        </div>
        {selectedRowKeys?.length ? (
          <div className="gap-8 row">
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={() => handleApproveDeclinePopup(false)}
              danger
              upperCase
            >
              {BTN_TXT.DECLINE}
            </Button>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={() => handleApproveDeclinePopup(true)}
              upperCase
            >
              {BTN_TXT.APPROVE}
            </Button>
          </div>
        ) : (
          <Can
            I={ACTIONS.UPLOAD}
            a={contractSubject(SUBJECTS.ITEM_LIST, details)}
          >
            {UploadBtn()}
          </Can>
        )}
      </div>
      {!firstLoad && (
        <Table<TContractItemsListRecord>
          dataSource={tableData}
          className="contacts-item-list-table"
          columns={columns}
          rowSelection={
            isAbleToApproveDecline
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow
                }
              : undefined
          }
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams(debouncedSearch, false)}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          renderButton={
            ability.can(
              ACTIONS.UPLOAD,
              contractSubject(SUBJECTS.ITEM_LIST, details)
            ) &&
            ((isVendor && activeTab === 'pending') ||
              (!isVendor && activeTab !== 'pending')) &&
            UploadBtn()
          }
        />
      )}
      <SendForApprovalModal callback={() => getTableData({})} />
      {reconcileDuplicatesPopup.state.visible && <ReconcileDuplicatesPopup />}
      {compareRecordsPopup.state.visible && (
        <CompareRecordPopup compareId={compareId} />
      )}
      <DeclinePopup
        callBack={approveDeclainAction}
        itemsCount={selectedRowKeys.length}
      />
      <ApprovePopup
        callBack={approveDeclainAction}
        itemsCount={selectedRowKeys.length}
      />
      <ContractUpdateItemsListPopup
        replaceOldItems={replaceOldItems}
        setReplaceOldItems={setReplaceOldItems}
        handleUploadFile={handleUploadFile}
      />
    </div>
  )
}
