import Fetcher from '../../../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../../../constants'
import { HospitalsListResponse } from './types'

const fetcher = new Fetcher({})

export const getHospitalsList = async (uuid: string) =>
  fetcher.request<unknown, HospitalsListResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.REBATE}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.HOSPITAL}`,
    method: HTTP_METHODS.GET
  })
