import { MarketShareHospital } from './types'

export const HOSPITAL_OPTIONS = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No, apply to individual Hospitals',
    value: false
  }
]

export const DEFAULT_HOSPITAL_VALUES: MarketShareHospital = {
  hospital: null,
  baseline_spend: null
}
