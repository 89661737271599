import { Input } from 'components/Input'
import { Select } from '../../../../../components/Select'
import {
  minRebateValueValidator,
  percentageRebateValidator
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/utils'
import {
  PROPS_INPUT_NUMBER,
  MONTH_OPTIONS
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { LABELS, PLACEHOLDERS } from 'constants/txt'
import { GrowthRebateProps } from './types'

const GrowthRebateCondition = ({ handleChangeForm }: GrowthRebateProps) => (
  <div className="column gap-16">
    <div className="row gap-12">
      <Input.Number
        propsItem={{
          name: 'baseline_spend',
          label: LABELS.BASELINE_SPEND,
          rules: [
            {
              validator: minRebateValueValidator
            }
          ]
        }}
        propsInputNumber={PROPS_INPUT_NUMBER}
      />
      <Input.Number
        propsItem={{
          name: 'amount',
          label: LABELS.REBATE_AMOUNT,
          rules: [
            {
              validator: percentageRebateValidator
            }
          ]
        }}
        propsInputNumber={PROPS_INPUT_NUMBER}
      />
    </div>
    <Select
      options={MONTH_OPTIONS}
      propsItem={{
        name: 'period_threshold',
        label: LABELS.PERIOD_THRESHOLD
      }}
      propsSelect={{
        placeholder: PLACEHOLDERS.PLEASE_SELECT,
        onChange: handleChangeForm
      }}
    />
  </div>
)

export default GrowthRebateCondition
