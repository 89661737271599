import { useState } from 'react'
import { useDispatch } from 'react-redux'
import ShowMore from 'components/ShowMore/ShowMore'
import Typography from 'components/Typography/Typography'
import ChatDetails from 'features/ChatDetails/ChatDetails'
import { Button } from '../../components/Button'
import { Forms } from 'components/Forms'
import { updateChatDetailsAsync } from 'redux/store/chatDetails/actions'
import { ACTIONS, chatSubject, SUBJECTS } from 'features/Chat/Abilities'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'
import { Can } from 'features/Chat/Providers/ChatAbilityProvider'
import { useChatPopup } from '../Chat/Providers/ChatPopupProvider'
import { safeString } from 'helper/common'
import { updateChatTitle } from '../../redux/store/chats/slice'
import { TYPOGRAPHY_WEIGHT } from '../../components/Typography'
import { BTN_TXT } from 'constants/txt'
import { IGroupChatDetails } from 'redux/store/chatDetails/types'

export const GroupChatDetails = () => {
  const dispatch = useDispatch<any>()
  const chatContext = useChatContext<IGroupChatDetails>()
  const { deleteModal } = useChatPopup()

  const { chat } = chatContext.state

  const [isEditable, setIsEditable] = useState(false)

  const closeEditForm = () => setIsEditable(false)

  const updateChat = (values) => {
    if (!chat?.uuid) {
      throw new Error('Chat id not provided')
    }

    dispatch(updateChatDetailsAsync(chat.uuid, values)).then(() => {
      closeEditForm()
    })
    dispatch(updateChatTitle({ uuid: chat.uuid, title: values.name }))
  }

  return (
    <ChatDetails name="GC" chatName={chat?.name} hideName={isEditable}>
      {chat &&
        (!isEditable ? (
          <>
            <ShowMore className="chat-details-container__description">
              <Typography.Body1>
                {safeString(chat.description)}
              </Typography.Body1>
            </ShowMore>
            <div className="row gap-12 mt-32">
              <Can I={ACTIONS.EDIT} a={chatSubject(SUBJECTS.GROUP_CHAT, chat)}>
                <Button onClick={() => setIsEditable(true)} upperCase>
                  {BTN_TXT.EDIT}
                </Button>
              </Can>
              <Can
                I={ACTIONS.DELETE}
                a={chatSubject(SUBJECTS.GROUP_CHAT, chatContext.state.chat)}
              >
                <Button onClick={deleteModal.actions.open} upperCase danger>
                  <Typography.Button weight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                    {BTN_TXT.DELETE_CHAT}
                  </Typography.Button>
                </Button>
              </Can>
            </div>
          </>
        ) : (
          <Forms.EditGroupChat
            chat={chat}
            onSubmit={updateChat}
            onCancel={closeEditForm}
          />
        ))}
    </ChatDetails>
  )
}
