import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Link } from 'components/Link'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { Sorter } from 'components/Sorter/Sorter'
import {
  TFinancialsColumns,
  TFinancialsList,
  TFinancialsListFilters,
  TFinancialsListFiltersFunc,
  TReportsData,
  TActualPaymentsData,
  TActualPaymentsList
} from './types'
import { Tooltip } from '../../components/Tooltip/Tooltip'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { ReactComponent as Renew16 } from '../../assets/svg/Renew16.svg'
import {
  canSeeMyContracts,
  fileNameFormatter,
  safeNumber
} from '../../helper/common'
import { Attachment } from '../../components/Attachment/Attachment'
import {
  FINANCIAL_STATUS_TYPES_TO_CLASSNAME,
  Status
} from '../../components/Status'
import { currencyFormatter } from '../../features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import moment from 'moment'
import { Dropdown } from '../../components/Dropdown'
import { ReactComponent as FilterApplied16 } from '../../assets/svg/FilterApplied16.svg'
import { ReactComponent as Filter16 } from '../../assets/svg/Filter16.svg'
import { routes } from '../../router'
import './styles.scss'
import { TOOLTIP } from '../../constants'
import { requestMonthFormat } from '../../utils/moment'

export const initialPopupData = {
  declinePopup: false,
  acceptPopup: false,
  resendPopup: false
}

export const FINANCIAL_STATUS = {
  PENDING_FOR_PAYMENT: 'pending_for_payment',
  PAYMENT_IN_PROGRESS: 'payment_in_progress',
  DECLINED: 'declined',
  PAID: 'paid',
  SENT: 'sent',
  PAYMENT_FAILED: 'payment_failed'
}

export const STATUS_TYPE_FOR_RESEND = {
  sent: 'sent',
  declined: 'declined'
}

export const FACILITIES_OPTIONS_LABELS = {
  communities: 'Communities',
  health_systems: 'Health systems',
  hospitals: 'Hospitals'
}

export const FINANCIAL_STATUS_LABEL = {
  sent: 'Sent',
  declined: 'Declined',
  waiting_for_response: 'Waiting for response',
  pending_for_payment: 'Pending for payment',
  payment_in_progress: 'Payment in progress',
  paid: 'Paid',
  failed: 'Failed',
  payment_failed: 'Payment failed'
}

export const FINANCIAL_STATUS_FILTER = [
  {
    label: 'Paid',
    value: 'paid',
    index: 0
  },
  {
    label: 'Sent',
    value: 'sent',
    index: 1
  },
  {
    label: 'Waiting for response',
    value: 'sent',
    index: 1
  },
  {
    label: 'Pending for payment',
    value: 'pending_for_payment',
    index: 2
  },
  {
    label: 'Payment in progress',
    value: 'payment_in_progress',
    index: 3
  },
  {
    label: 'Declined',
    value: 'declined',
    index: 5
  },
  {
    label: 'Payment failed',
    value: 'payment_failed',
    index: 4
  }
]

export const columns = ({
  onResendHandler,
  isCanResend,
  isVendor,
  applyFilters,
  filters,
  user
}: TFinancialsColumns): ColumnsType<TFinancialsList> => [
  {
    dataIndex: 'contract_number',
    key: 'contract_number',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TFinancialsList>
        dataIndex="contract_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Contract number
      </Sorter>
    ),
    render: (_, { contract_number, contract_id }) => (
      <CellWithSubtitle
        title={
          <Link
            to={`${routes.contracts}${
              canSeeMyContracts(user.role) ? '/my_contracts' : ''
            }/${contract_id}`}
          >
            {contract_number}
          </Link>
        }
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: isVendor ? 'facility_name' : 'vendor_name',
    key: isVendor ? 'facility_name' : 'vendor_name',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TFinancialsList>
        dataIndex={isVendor ? 'facility_name' : 'vendor_name'}
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        {isVendor ? 'Facility name' : 'Vendor name'}
      </Sorter>
    ),
    render: (_, { facility_name, vendor_name }) => (
      <CellWithSubtitle title={isVendor ? facility_name : vendor_name} />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'start_period',
    key: 'start_period',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TFinancialsList>
        dataIndex="start_period"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Period
      </Sorter>
    ),
    render: (_, { start_period }) => (
      <CellWithSubtitle
        title={`${moment(start_period).format(requestMonthFormat)} - ${moment(
          start_period
        )
          .add(2, 'month')
          .format(requestMonthFormat)}`}
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'calculation_report_files',
    key: 'calculation_report_files',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TFinancialsList>
        dataIndex="calculation_report_files"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Report
      </Sorter>
    ),
    render: (_, { calculation_report_files }) => (
      <Attachment.Table
        filename={fileNameFormatter(calculation_report_files[0].file)}
        link={calculation_report_files[0].file}
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'type',
    key: 'type',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TFinancialsList>
        dataIndex="type"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Type
      </Sorter>
    ),
    render: (_, { type }) => (
      <CellWithSubtitle
        title={type.charAt(0).toUpperCase() + type.slice(1).replace('_', ' ')}
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'amount',
    key: 'amount',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TFinancialsList>
        dataIndex="amount"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Total, $
      </Sorter>
    ),
    render: (_, { amount }) => (
      <CellWithSubtitle
        className="financial-table-total-row"
        title={currencyFormatter(safeNumber(amount).toFixed(2))}
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TFinancialsList>
        dataIndex="status"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Status
      </Sorter>
    ),
    sorter: true,
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TFinancialsListFilters,
        TFinancialsListFiltersFunc,
        unknown
      >
        items={getFinancialStatusFilter(isVendor)}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="status"
        asyncSearch
        {...props}
      />
    ),
    filterIcon: !!filters?.statuses?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    render: (_, { status, uuid, type }) => (
      <div className="financials-table__status-column">
        <Status.Default className={FINANCIAL_STATUS_TYPES_TO_CLASSNAME[status]}>
          {getFinancialStatus(status, isVendor)}
        </Status.Default>
        {isCanResend && STATUS_TYPE_FOR_RESEND[status] && (
          <Tooltip title={TOOLTIP.THE_REPORT_WILL_BE_REGENERATED_AND_RESEND}>
            <Button
              icon={<Renew16 />}
              onClick={() => onResendHandler(`${type}/${uuid}`)}
              type={BUTTON_TYPES.DEFAULT}
              className="financials-table__resend-button"
            />
          </Tooltip>
        )}
      </div>
    ),
    showSorterTooltip: false
  }
]

export const REPORTS_COLORS = {
  sent: 'blue',
  declined: 'light_red',
  waiting_for_response: 'blue',
  pending_for_payment: 'yellow',
  payment_in_progress: 'gray',
  paid: 'green',
  payment_failed: 'red'
} as const

export const ACTUAL_PAYMENTS_COLORS = ['green', 'yellow', 'blue'] as const

const ACTUAL_PAYMENTS_STATUSES = ['admin_fee', 'total']
const OBJECT_KEYS_ACTUAL_PAYMENTS = [
  'payments',
  'expected_payments',
  'percentage'
]

const LABEL_ACTUAL_PAYMENTS = {
  total: 'Total',
  admin_fee: 'Admin fee'
}

export const adjustActualPaymentsData = (data: TActualPaymentsList) => {
  return ACTUAL_PAYMENTS_STATUSES.map((status) => {
    const entity = { status: LABEL_ACTUAL_PAYMENTS[status] }
    OBJECT_KEYS_ACTUAL_PAYMENTS.forEach((key) => {
      entity[key] = Number(data[`${status}_${key}`])
    })
    return entity
  }) as TActualPaymentsData
}

export const INITIAL_DATA_REPORTS_LEGEND = [
  {
    status: 'paid',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'sent',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'waiting_for_response',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'pending_for_payment',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'payment_in_progress',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'payment_failed',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'declined',
    amount: 0,
    percentage: '0'
  }
]

export const INITIAL_DATA_PAYMENTS_LEGEND = [
  {
    status: 'Admin fee',
    payments: 0,
    expected_payments: 0,
    percentage: 0
  },
  {
    status: 'Rebates',
    payments: 0,
    expected_payments: 0,
    percentage: 0
  },
  {
    status: 'Total',
    payments: 0,
    expected_payments: 0,
    percentage: 0
  }
]

export const getFinancialStatus = (status: string, isVendor: boolean) => {
  if (status === 'sent') {
    return isVendor
      ? FINANCIAL_STATUS_LABEL.waiting_for_response
      : FINANCIAL_STATUS_LABEL.sent
  } else {
    return FINANCIAL_STATUS_LABEL[status]
  }
}

export const getFinancialStatusFilter = (isVendor: boolean) => {
  return FINANCIAL_STATUS_FILTER.filter(
    (i) => !i.label.includes(isVendor ? 'Sent' : 'Waiting')
  )
}

export const getFinancialStatusLegend = (isVendor: boolean) => {
  return INITIAL_DATA_REPORTS_LEGEND.filter(
    (i) => !i.status.includes(isVendor ? 'sent' : 'waiting')
  )
}

export const getFinancialStatusLegendOrder = (data: TReportsData[]) => {
  return data.sort((a, b) => {
    const indexA =
      FINANCIAL_STATUS_FILTER?.find((i) => a.status === i.value)?.index || 0
    const indexB =
      FINANCIAL_STATUS_FILTER?.find((i) => b.status === i.value)?.index || 0
    if (indexA < indexB) return -1
    else return 1
  })
}

export const REPORTS_CHAT_COLORS = {
  sent: '#73A8D3',
  declined: '#FF9999',
  waiting_for_response: '#73A8D3',
  pending_for_payment: '#FAD16B',
  payment_in_progress: '#E8EBED',
  paid: '#62DA84',
  payment_failed: '#FF6666'
}
export const quarterNumber = moment()
  .quarter(moment().quarter())
  .startOf('quarter')
  .format('Q')

export const getStartOfQuarter = () =>
  moment()
    .quarter(+quarterNumber - 1)
    .startOf('quarter')
export const getEndOfQuarter = () =>
  moment()
    .quarter(+quarterNumber - 1)
    .endOf('quarter')
