import { useEffect, useState } from 'react'
import { getTableMetadata } from '../api'
import { Sorter } from '../../../components/Sorter/Sorter'
import { SortOrder } from 'antd/es/table/interface'
import { ColumnsType } from 'antd/es/table'
import { TContractItemsListRecord } from '../types'
import { Status } from '../../../components/Status'
import {
  ITEM_LIST_STATUS,
  ITEM_LIST_STATUS_NAME,
  ITEM_LIST_STATUS_TYPES_TO_CLASSNAME
} from '../constants'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { ReactComponent as InformationSvg } from 'assets/svg/Information.svg'
import { Tooltip } from '../../../components/Tooltip/Tooltip'

export const useColumns = (
  uuid: string,
  tableData: TContractItemsListRecord[],
  activeTab: string,
  openComparePopup: (id: string) => void
) => {
  const [columns, setColumns] = useState<ColumnsType<any>>([])

  const setColumnsFromRequest = async () => {
    if (!uuid) return
    getTableMetadata(uuid, {
      params: { status: activeTab === 'pending' ? 'pending' : 'active' }
    }).then((resp) => {
      if (!resp.data?.results) {
        return
      }

      const returnedColumns: ColumnsType<any> = resp.data.results.map((i) => ({
        dataIndex: i.key,
        key: i.key,
        title: ({ sortOrder, sortColumn }) => (
          <Sorter<TContractItemsListRecord>
            dataIndex={i.key}
            sortColumn={sortColumn}
            sortOrder={sortOrder as SortOrder}
          >
            {i.value}
          </Sorter>
        ),
        sorter: true,
        showSorterTooltip: false
      }))
      if (activeTab === 'pending' && !!returnedColumns?.length) {
        returnedColumns.unshift({
          dataIndex: 'status',
          key: 'status',
          title: ({ sortOrder, sortColumn }) => (
            <Sorter<TContractItemsListRecord>
              dataIndex={'status'}
              sortColumn={sortColumn}
              sortOrder={sortOrder as SortOrder}
            >
              Status
            </Sorter>
          ),
          width: 190,
          render: (_, record) => {
            return (
              <div className="separate-item row gap-8 align-center">
                <Status.Default
                  className={ITEM_LIST_STATUS_TYPES_TO_CLASSNAME[record.status]}
                >
                  {ITEM_LIST_STATUS_NAME[record.status]}
                </Status.Default>
                {record.status === ITEM_LIST_STATUS.REPLACED && (
                  <Tooltip title={'Compare records'}>
                    <Button.Icon
                      className="replaced-info-button"
                      type={BUTTON_TYPES.DEFAULT}
                      onClick={() => openComparePopup(record.key)}
                      icon={<InformationSvg />}
                    />
                  </Tooltip>
                )}
              </div>
            )
          }
        })
      }
      setColumns(returnedColumns)
    })
  }
  useEffect(() => {
    setColumnsFromRequest()
  }, [uuid, tableData])

  return {
    columns
  }
}
