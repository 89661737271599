import { FC } from 'react'
import Typography from '../../../components/Typography/Typography'
import useRouter from '../../../hooks/useRouter'
import { routes } from '../../../router'
import { HealthSystemsProps } from './types'
import './styles.scss'

export const HealthSystems: FC<HealthSystemsProps> = ({ healthSystems }) => {
  const { push } = useRouter()

  const onOpenHs = (id: string) =>
    push(`${routes.myCommunity}${routes.healthSystem}/${id}/`)

  return (
    <div className="health-systems-list">
      {!!healthSystems.length ? (
        <div className="health-systems-list__list">
          {healthSystems.map((i) => (
            <div
              key={i.uuid}
              onClick={() => onOpenHs(i.uuid)}
              className="health-systems-list__list-item"
            >
              <Typography.Body1>{i.name}</Typography.Body1>
            </div>
          ))}
        </div>
      ) : (
        <div className="health-systems-list__empty row justify-center">
          <Typography.Headline6 className="row justify-center">
            The list is empty
          </Typography.Headline6>
        </div>
      )}
    </div>
  )
}
