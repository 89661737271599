import {
  currencyFormatter,
  currencyParser
} from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { getMonthOptions } from './utils'
import { HOSPITAL_OPTIONS } from './MarketShareRebateCondition/constants'

export const MONTH_OPTIONS = getMonthOptions()

export const REBATE_CONDITIONS_TYPE = {
  NO_REBATE: 'no_rebate',
  STRAIGHT_REBATE: 'straight',
  GROWTH_REBATE: 'growth',
  MARKET_SHARE_REBATE: 'market_share'
} as const

export const REBATE_CONDITIONS_TITLE = {
  no_rebate: 'No rebate',
  straight: 'Straight',
  growth: 'Growth',
  market_share: 'Market share'
} as const

export const REBATE_CONDITIONS_TYPE_NAME = {
  [REBATE_CONDITIONS_TYPE.NO_REBATE]: 'No rebate',
  [REBATE_CONDITIONS_TYPE.STRAIGHT_REBATE]: 'Straight rebate',
  [REBATE_CONDITIONS_TYPE.GROWTH_REBATE]: 'Growth rebate',
  [REBATE_CONDITIONS_TYPE.MARKET_SHARE_REBATE]: 'Market share rebate'
}

export const REBATE_CONDITIONS_TYPE_OPTIONS = Object.values(
  REBATE_CONDITIONS_TYPE
).map((type) => ({
  label: REBATE_CONDITIONS_TYPE_NAME[type],
  value: type
}))

export const INITIAL_REBATE_CONDITIONS = {
  [REBATE_CONDITIONS_TYPE.NO_REBATE]: {},
  [REBATE_CONDITIONS_TYPE.STRAIGHT_REBATE]: {
    amount: null,
    period_threshold: MONTH_OPTIONS[11].value
  },
  [REBATE_CONDITIONS_TYPE.GROWTH_REBATE]: {
    baseline_spend: null,
    amount: null,
    period_threshold: MONTH_OPTIONS[11].value
  },
  [REBATE_CONDITIONS_TYPE.MARKET_SHARE_REBATE]: {
    baseline_spend: null,
    amount: 0,
    period_threshold: MONTH_OPTIONS[11].value,
    apply_to_all_hospitals: HOSPITAL_OPTIONS[0].value,
    hospitals_rebates: []
  }
}

export const PROPS_INPUT_NUMBER = {
  min: '0',
  maxLength: 64,
  controls: false,
  formatter: currencyFormatter,
  parser: currencyParser
}
