import { SortOrder } from 'antd/lib/table/interface'
import { AxiosResponse, CancelToken } from 'axios'
import { TPageInfo, TQueryParams } from 'components/Table/types'
import { ITEM_LIST_STATUS } from './constants'
import { FIREBASE_STATUSES } from '../../constants'

export type TContractItemsListParams = TQueryParams

export type TContractItemsRequestData = {
  sortField?: string
  sortOrder?: SortOrder
  page?: TPageInfo
}

type TDynamicHeader = {
  [key: string]: string | number
}

export type TContractItemsMetadataList = {
  uuid: string
  key: string
  value: string
}

export type TContractItemsMetadataResponse = {
  count: number
  next?: string
  previous?: string
  results: TContractItemsMetadataList[]
}
export type TDecision = {
  contract_item: string
  decision: 'approved' | 'declined'
}

export type TSetDecisionRequest = (
  uuid: string,
  data: TDecision[]
) => Promise<AxiosResponse<unknown>>
export type TMetadataRequest = (
  uuid: string,
  data: { params: { status: string } }
) => Promise<AxiosResponse<TContractItemsMetadataResponse>>
export type TResolveReconciliationRequest = (
  uuid: string,
  reconciliationId: string,
  data: TReconciliationDecision[]
) => Promise<AxiosResponse<unknown>>
export type TContractItemsListRecord = {
  [key: string]: string | number
}
export type TReconciliationDecision = {
  reconcile_item: string
  contract_item: string
  pre_loaded_contract_item: string
}
export type TContractItemsList = {
  lineno: number
  record: TContractItemsListRecord
  uuid: string
  pending_substatus: ValueOfObject<typeof ITEM_LIST_STATUS>
}

export type TContractItemsListResponse = {
  count: number
  next?: string
  previous?: string
  results: TContractItemsList[]
}

export type TUploadContractItemsListResponse = {
  reconciliation_id: string
}

export type TContractReconciliationItemsListResponse = {
  header: TDynamicHeader
  mode: string
  notification_status: string
  status: string
  uuid: string
  reconcile_items: TReconciliationItem[]
}

export type TReconciliationItem = {
  contract_items: TContractItemsList[]
  pre_loaded_contract_items: TContractItemsList[]
  uuid: string
}

export type TQueryKeys = {
  withDuplicatesModal?: boolean
}

export type TContractItemsListRequest = (
  data: any,
  uuid: string
) => Promise<AxiosResponse<TContractItemsListResponse>>

export type TContractPendingItemsListRequest = (
  data: {
    params: any
    cancelToken?: CancelToken
  },
  uuid: string
) => Promise<AxiosResponse<TContractItemsListResponse>>

export type TReplacedHistoryListRequest = (
  data: { params: TQueryParams },
  uuid: string,
  replacedId: string
) => Promise<AxiosResponse<TContractItemsListResponse>>

export type TCheckCurrentItemListWasGeneratedResponse = {
  file: string
}

export type TDownloadCurrentItemListResponse = {
  uuid: string
}

export type TUploadContractItemsStatus = {
  uuid: string
  notification_status: FIREBASE_STATUSES
  file_ref: {
    file: string
  }
}

export enum RECONCILIATION_STATUSES {
  COMPLETED = 'completed',
  WAITING_FOR_REVIEW = 'waiting_for_review'
}

export type TReconciliationItemsListRequest = (
  data: any,
  uuid: string,
  reconciliationId: string
) => Promise<AxiosResponse<TContractReconciliationItemsListResponse>>
