import { useContractDetailsPopup } from '../../ContractDetails/Providers/ContractDetailsPopupProvider'
import { Popup } from '../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { Typography } from '../../../components/Typography'
import { TApproveDeclinePopup } from './types'

const ApprovePopup = ({ itemsCount, callBack }: TApproveDeclinePopup) => {
  const { approveItemListPopup } = useContractDetailsPopup()
  return (
    <Popup
      {...approveItemListPopup.state}
      title={`Approve item${itemsCount > 1 ? 's' : ''}?`}
      width={442}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={approveItemListPopup.actions.close}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            onClick={() => callBack(true)}
            type={BUTTON_TYPES.PRIMARY}
            upperCase
          >
            {BTN_TXT.APPROVE}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        Approved item{itemsCount > 1 ? 's' : ''} will be added to the Item list.
        Do you want to proceed?
      </Typography.Body1>
    </Popup>
  )
}

export default ApprovePopup
