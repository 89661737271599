import Button from '../../../components/Button/Button'
import { BUTTON_TYPES } from '../../../components/Button'
import { TEvaluate, TEvaluateModal } from './types'
import './style.scss'
import { useCognusRepositoryPopup } from '../Providers/CognusRepositoryPopupProvider'
import Popup from 'components/Popup/Popup'
import Typography from '../../../components/Typography/Typography'
import { BTN_TXT } from '../../../constants'
import { useContractPageContext } from '../../../pages/Contracts/Providers/ContractPageContextProvider'

export const EvaluateContainer = ({ uuid }: TEvaluate) => {
  const { evaluateContractPopup } = useCognusRepositoryPopup()
  const { setEvaluateData } = useContractPageContext().actions
  const { actions } = evaluateContractPopup
  const handleModal = () => {
    setEvaluateData([uuid])
    actions.open()
  }

  return (
    <div className="evaluate-container">
      <Button type={BUTTON_TYPES.GHOST} onClick={handleModal}>
        Evaluate
      </Button>
    </div>
  )
}
export const EvaluateModal = ({
  callback,
  contractIdsForEvaluation
}: TEvaluateModal) => {
  const { evaluateContractPopup } = useCognusRepositoryPopup()
  const { state, actions } = evaluateContractPopup
  const { evaluateData } = useContractPageContext().state
  const { handleEvaluation } = useContractPageContext().actions
  const onClick = () => {
    handleEvaluation(contractIdsForEvaluation, callback)
  }

  return (
    <div className="evaluate-modal">
      <Popup
        visible={state.visible}
        onCancel={actions.close}
        title={`Evaluate the contract${evaluateData?.length > 1 ? 's' : ''}?`}
        width={490}
        footer={
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={actions.close}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button type={BUTTON_TYPES.PRIMARY} onClick={onClick} upperCase>
              {BTN_TXT.EVALUATE}
            </Button>
          </>
        }
      >
        <Typography.Body1>
          You will be asked to provide your invoice data to analyze savings
          estimates and run clinical review for the selected
          {` contract${evaluateData?.length > 1 ? 's' : ''}`}. Do you want to
          proceed?
        </Typography.Body1>
      </Popup>
    </div>
  )
}
